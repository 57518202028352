import { History, Laptop, Tune } from '@mui/icons-material';
import { Box, FormControl, FormControlLabel, FormLabel, Menu, Radio, RadioGroup } from '@mui/material';
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowModel, GridValidRowModel } from '@mui/x-data-grid-premium';
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { LayoutContext } from 'wcz-layout';
import { AutocompleteEditInputCell, GridDeleteCellItem, GridToolbar, GridToolbarProps, TableContainer, editInputCellValidation } from 'wcz-x-data-grid';
import LocationMaterials from '../components/location/LocationMaterials';
import LocationTrailTable from '../components/location/LocationTrailTable';
import Location, { LocationOptions } from '../models/Location';
import { useCreateLocation, useDeleteLocation, useGetLocationOptions, useGetLocations, useUpdateLocation } from '../services/LocationService';

const locationOptionsInit: LocationOptions = { buildings: [], equipmentRooms: [], racks: [], shelfs: [], boxes: [] };

export default function Locations() {
    const { changeTitle, t, i18n } = useContext(LayoutContext);
    const [materialLocationId, setMaterialLocationId] = useState<string>("");
    const [trailLocationId, setTrailLocationId] = useState<string>("");
    const [warehouseFilterMenu, setWarehouseFilterMenu] = useState<{ mouseX: number; mouseY: number; } | null>(null);
    const [filter, setFilter] = useState<"allLocations" | "warehouse" | "notWarehouse">("allLocations");

    const { data: locations = [], isLoading } = useGetLocations();
    const { data: options = locationOptionsInit } = useGetLocationOptions();

    const create = useCreateLocation();
    const update = useUpdateLocation();
    const remove = useDeleteLocation();

    useEffect(() => changeTitle(t("Locations")), [i18n.language]);

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'id', type: 'actions', width: 50,
            getActions: (params: any) => [
                <GridActionsCellItem key="materials" icon={<Laptop />} label={t("Materials")} onClick={() => setMaterialLocationId(params.id)} showInMenu />,
                <GridActionsCellItem key="history" icon={<History />} label={t("History")} onClick={() => setTrailLocationId(params.id)} showInMenu />,
                <GridDeleteCellItem key="remove" id={params.id} remove={remove} showInMenu />
            ],
        },
        { field: 'isWarehouse', headerName: t("IsWarehouse"), width: 150, type: 'boolean', editable: true },
        {
            field: 'name', headerName: t("Name"), width: 200, editable: true,
            preProcessEditCellProps: (params) => {
                const isWarehouse: boolean = params.otherFieldsProps!.isWarehouse.value;
                if (isWarehouse)
                    return undefined;

                return editInputCellValidation.hasLength(params, 1);
            },
        },
        {
            field: 'building', headerName: t("Building"), editable: true, preProcessEditCellProps: params => {
                const isWarehouse: boolean = params.otherFieldsProps!.isWarehouse.value;
                if (isWarehouse)
                    return editInputCellValidation.hasLength(params, 2, 2);
            },
            renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.buildings} freeSolo />
        },
        {
            field: 'equipmentRoom', headerName: t("EquipmentRoom"), width: 150, editable: true, preProcessEditCellProps: params => {
                const isWarehouse: boolean = params.otherFieldsProps!.isWarehouse.value;
                if (isWarehouse)
                    return editInputCellValidation.hasLength(params, 2, 2);
            },
            renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.equipmentRooms} freeSolo />,
        },
        {
            field: 'rack', headerName: t("Rack"), editable: true, preProcessEditCellProps: params => {
                const isWarehouse: boolean = params.otherFieldsProps!.isWarehouse.value;
                if (isWarehouse)
                    return editInputCellValidation.hasLength(params, 3, 3);
            },
            renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.racks} freeSolo />
        },
        {
            field: 'shelf', headerName: t("Shelf"), editable: true, preProcessEditCellProps: params => {
                const isWarehouse: boolean = params.otherFieldsProps!.isWarehouse.value;
                if (isWarehouse)
                    return editInputCellValidation.hasLength(params, 2, 2);
            },
            renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.shelfs} freeSolo />
        },
        {
            field: 'box', headerName: t("Box"), editable: true, width: 150,
            renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.boxes} freeSolo />
        },
        { field: 'remark', headerName: t("Remark"), editable: true, width: 350, },
    ] as GridColDef[], [options, i18n.language]);

    const processRowUpdate = async (row: GridRowModel<GridValidRowModel>): Promise<GridValidRowModel> => {
        row.isWarehouse = !row.name;
        if (row.isWarehouse) {
            row.name = `${row.building}${row.equipmentRoom}${row.rack}${row.shelf}${row.box ?? ""}`;
        } else {
            row.building = undefined;
            row.equipmentRoom = undefined;
            row.rack = undefined;
            row.shelf = undefined;
            row.box = undefined;
        }

        if (row.isNew)
            await create.mutateAsync(row as Location);
        else {
            await update.mutateAsync(row as Location);
        }

        return { ...row, isNew: false };
    };

    const openWarehouseFilterMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => setWarehouseFilterMenu({ mouseX: event.clientX, mouseY: event.clientY + 20 }), []);

    const closeWarehouseFilterMenu = useCallback(() => setWarehouseFilterMenu(null), []);

    const filterLocations = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value as "allLocations" | "warehouse" | "notWarehouse");
        closeWarehouseFilterMenu();
    }, []);

    const filteredLocations = useMemo(() => locations.filter(location => {
        if (filter === "allLocations")
            return true;
        else if (filter === "warehouse")
            return location.isWarehouse;
        else if (filter === "notWarehouse")
            return !location.isWarehouse;
        return false;
    }), [locations, filter]);

    return (
        <Fragment>
            <TableContainer>
                <DataGridPremium rows={filteredLocations} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading}
                    editMode="row" processRowUpdate={processRowUpdate} slotProps={{
                        toolbar: {
                            items: [
                                { title: t("WarehouseFilter"), onClick: openWarehouseFilterMenu, icon: <Tune /> },
                            ]
                        } as GridToolbarProps
                    }} />
            </TableContainer>

            <LocationMaterials locationId={materialLocationId} setLocationId={setMaterialLocationId} />
            <LocationTrailTable primaryKey={trailLocationId} setPrimaryKey={setTrailLocationId} />

            <Menu open={warehouseFilterMenu !== null} onClose={closeWarehouseFilterMenu} anchorReference="anchorPosition" variant="menu"
                anchorPosition={warehouseFilterMenu !== null ? { top: warehouseFilterMenu.mouseY, left: warehouseFilterMenu.mouseX } : undefined}>
                <Box sx={{ width: { xs: 230, sm: 250, md: 270 }, px: 2 }}>
                    <FormControl>
                        <FormLabel>{t("WarehouseFilter")}</FormLabel>
                        <RadioGroup value={filter} onChange={filterLocations}>
                            <FormControlLabel value="allLocations" control={<Radio />} label={t("AllLocations")} />
                            <FormControlLabel value="warehouse" control={<Radio />} label={t("WarehouseLocations")} />
                            <FormControlLabel value="notWarehouse" control={<Radio />} label={t("NotWarehouseLocations")} />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Menu>
        </Fragment>
    );
}