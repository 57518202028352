import { MoreVert } from "@mui/icons-material";
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import { Fragment, useState } from "react";

interface Item {
    icon: React.ReactNode,
    onClick: () => void,
    label: string
}
interface CardActionItemsProps {
    items: Item[]
}

export default function CardActionItems(props: CardActionItemsProps) {
    const { items } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <Box sx={{ display: { xs: 'none', sm: 'inherit' } }}>
                {items.map(item => <Button key={item.label} size="small" startIcon={item.icon} onClick={item.onClick}>{item.label}</Button>)}
            </Box>

            <Box sx={{ display: { xs: 'inherit', sm: 'none' } }}>
                <IconButton onClick={handleClick}>
                    <MoreVert />
                </IconButton>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    {items.map((item) => (
                        <MenuItem key={item.label} onClick={item.onClick}>
                            {item.icon}
                            {item.label}
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
        </Fragment>
    );
}