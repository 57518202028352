import { QrCode } from "@mui/icons-material";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import moment from "moment";
import { Fragment, useCallback, useContext, useMemo, useState } from "react";
import { isMobile, LayoutContext } from "wcz-layout";
import { MaterialState } from "../../models/enums/MaterialState";
import Location from "../../models/Location";
import Material, { MaterialOptions } from "../../models/Material";
import MaterialType from "../../models/MaterialType";
import { getWarrantyDate } from "../../utils/MaterialHelpers";
import DynamsoftScanner from "../common/DynamsoftScanner";
import HighValueMaterialTooltip from "../common/HighValueMaterialTooltip";

interface MaterialFormProps {
    material: Material,
    setMaterial: (material: Material) => void,
    locations: Location[],
    options: MaterialOptions
}

export default function MaterialForm(props: MaterialFormProps) {
    const { material, setMaterial, locations, options } = props;
    const [scanner, setScanner] = useState<'location' | 'serialNumber' | 'imei' | 'partNumber' | null>(null);
    const type: MaterialType | undefined = useMemo(() => options.types.find(t => t.name === material.type), [material.type, options]);
    const { t } = useContext(LayoutContext);

    const handleOnScan = (value: string) => {
        switch (scanner) {
            case "location": {
                const scannedLocation: Location | undefined = locations.find(l => l.name === value);
                if (scannedLocation)
                    setMaterial({ ...material, location: scannedLocation });

                break;
            }
            default: {
                setMaterial({ ...material, [String(scanner)]: value });
            }
        }

        setScanner(null);
    };

    const handleOnFixAssetChange = (value: string) => {
        if (!value)
            return setMaterial({ ...material, fixAsset: undefined });

        let fixAsset: string = value;

        if (fixAsset?.length === 5 && fixAsset.endsWith("9")) {
            const companyCode: string = fixAsset.substring(0, 4).toUpperCase();
            if (options.highestFixAsset)
                fixAsset = `${companyCode}${options.highestFixAsset + 1}`;
        }

        setMaterial({ ...material, fixAsset: fixAsset });
    };

    const handleOnFocusItId = useCallback(() => {
        if (options.highestItId) {
            const increasedItId = options.highestItId + 1;
            const paddedItId = increasedItId.toString().padStart(5, '0');
            setMaterial({ ...material, itId: `IT${paddedItId}` });
        }
    }, [material, options.highestItId]);

    return (
        <Fragment>
            <Autocomplete
                value={material.type ?? ""}
                options={options.types.map(t => t.name)}
                autoHighlight
                onInputChange={(e, value) => setMaterial({ ...material, type: value })}
                renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={t("Type")} margin="dense" required />}
            />
            {type?.hasSerialNumber &&
                <TextField variant="standard" fullWidth label="SN" margin="dense" value={material.serialNumber ?? ""} onChange={e => setMaterial({ ...material, serialNumber: e.target.value })}
                    InputProps={{
                        startAdornment: isMobile && (
                            <InputAdornment position="start" onClick={() => setScanner('serialNumber')} sx={{ cursor: 'pointer' }}>
                                <QrCode />
                            </InputAdornment>
                        ),
                        endAdornment: <HighValueMaterialTooltip />
                    }} />
            }
            {type?.hasFixAsset &&
                <TextField variant="standard" fullWidth label="FixAsset" margin="dense" value={material.fixAsset ?? ""} onChange={e => handleOnFixAssetChange(e.target.value)}
                    InputProps={{ endAdornment: <HighValueMaterialTooltip /> }} error={!!material.fixAsset && material.fixAsset.length !== 12} />

            }
            {type?.hasImei &&
                <TextField variant="standard" fullWidth label="IMEI" margin="dense" value={material.imei ?? ""} onChange={e => setMaterial({ ...material, imei: e.target.value })}
                    InputProps={{
                        startAdornment: isMobile && (
                            <InputAdornment position="start" onClick={() => setScanner('imei')} sx={{ cursor: 'pointer' }}>
                                <QrCode />
                            </InputAdornment>
                        ),
                        endAdornment: <HighValueMaterialTooltip />
                    }} />
            }
            {type?.hasManufacturer &&
                <Autocomplete
                    value={material.manufacturer ?? ""}
                    options={options.manufacturers}
                    freeSolo
                    autoHighlight
                    onInputChange={(e, value) => setMaterial({ ...material, manufacturer: value })}
                    renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={t("Manufacturer")} margin="dense" />}
                />
            }
            {type?.hasModel &&
                <Autocomplete
                    value={material.model ?? ""}
                    options={options.models}
                    freeSolo
                    autoHighlight
                    onInputChange={(e, value) => setMaterial({ ...material, model: value })}
                    renderInput={(params) => <TextField {...params} variant="standard" fullWidth label="Model" margin="dense" />}
                />
            }
            {type?.hasState &&
                <Autocomplete
                    value={material.state ?? ""}
                    options={Object.values(MaterialState)}
                    autoHighlight
                    onInputChange={(e, value) => setMaterial({ ...material, state: value as MaterialState })}
                    renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={t("State")} margin="dense" />}
                />
            }
            {type?.hasLocation &&
                <Autocomplete
                    value={material.location ?? null}
                    options={options.locations}
                    autoHighlight
                    getOptionLabel={option => option.name}
                    onChange={(e, value) => setMaterial({ ...material, location: value ?? undefined })}
                    renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={t("Location")} margin="dense"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: isMobile && (
                                <InputAdornment position="start" onClick={() => setScanner('location')} sx={{ cursor: 'pointer' }}>
                                    <QrCode />
                                </InputAdornment>
                            ),
                        }}
                    />}
                />
            }
            {type?.hasDepartment &&
                <TextField variant="standard" fullWidth label={t("Department")} margin="dense" value={material.department ?? ""} onChange={e => setMaterial({ ...material, department: e.target.value })} />
            }
            {type?.hasPartNumber &&
                <Autocomplete
                    value={material.partNumber ?? ""}
                    options={options.partNumbers}
                    freeSolo
                    autoHighlight
                    onInputChange={(e, value) => setMaterial({ ...material, partNumber: value })}
                    renderInput={(params) => <TextField {...params} variant="standard" fullWidth label="PN" margin="dense"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: isMobile && (
                                <InputAdornment position="start" onClick={() => setScanner('partNumber')} sx={{ cursor: 'pointer' }}>
                                    <QrCode />
                                </InputAdornment>
                            ),
                        }}
                    />}
                />
            }
            {type?.hasCompany &&
                <Autocomplete
                    value={material.company ?? ""}
                    options={options.companies}
                    freeSolo
                    autoHighlight
                    onInputChange={(e, value) => setMaterial({ ...material, company: value })}
                    renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={t("Company")} margin="dense" />}
                />
            }
            {type?.hasOrderDate &&
                <TextField variant="standard" fullWidth label={t("OrderDate")} margin="dense" value={moment(material.orderDate).formatDate() ?? ""} onChange={e => setMaterial({ ...material, orderDate: e.target.value })} type="date" InputLabelProps={{ shrink: true }} />
            }
            {type?.hasMonthsOfWarranty &&
                <TextField variant="standard" fullWidth label={t("Warranty")} margin="dense" value={material.monthsOfWarranty ?? 0} onChange={e => setMaterial({ ...material, monthsOfWarranty: Number(e.target.value) })} type="number"
                    InputProps={{ endAdornment: <InputAdornment position="end">{getWarrantyDate(material.orderDate, material.monthsOfWarranty)}</InputAdornment> }} />
            }
            {type?.hasUnderRepairTo &&
                <TextField variant="standard" fullWidth label={t("UnderRepairTo")} margin="dense" value={material.underRepairTo ?? ""} onChange={e => setMaterial({ ...material, underRepairTo: e.target.value })} type="date" InputLabelProps={{ shrink: true }} />
            }
            {type?.hasItId &&
                <TextField variant="standard" fullWidth label="IT ID" margin="dense" value={material.itId ?? ""} onChange={e => setMaterial({ ...material, itId: e.target.value })} onFocus={handleOnFocusItId} />
            }
            {type?.hasStockQuantity &&
                <TextField variant="standard" fullWidth label={t("StockQuantity")} margin="dense" value={material.stockQuantity ?? 0} onChange={e => setMaterial({ ...material, stockQuantity: Number(e.target.value) })} type="number" />
            }
            {type?.hasSafetyStock &&
                <TextField variant="standard" fullWidth label={t("SafetyStock")} margin="dense" value={material.safetyStock ?? 0} onChange={e => setMaterial({ ...material, safetyStock: Number(e.target.value) })} type="number" />
            }
            {type?.hasPrice &&
                <TextField variant="standard" fullWidth label={t("Price")} margin="dense" value={material.price ?? 0} onChange={e => setMaterial({ ...material, price: Number(e.target.value) })} type="number" />
            }
            {type?.hasCurrency &&
                <Autocomplete
                    value={material.currency ?? ""}
                    options={options.currencies}
                    freeSolo
                    onInputChange={(e, value) => setMaterial({ ...material, currency: value })}
                    renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={t("Currency")} margin="dense" />}
                />
            }
            {type?.hasHostname &&
                <TextField variant="standard" fullWidth label={t("Hostname")} margin="dense" value={material.hostname ?? ""} onChange={e => setMaterial({ ...material, hostname: e.target.value })} />
            }
            {type?.hasRemark &&
                <TextField variant="standard" fullWidth label={t("Remark")} margin="dense" value={material.remark ?? ""} onChange={e => setMaterial({ ...material, remark: e.target.value })} multiline />
            }
            {type?.hasIp &&
                <TextField variant="standard" fullWidth label="IP" margin="dense" value={material.ip ?? ""} onChange={e => setMaterial({ ...material, ip: e.target.value })} />
            }
            {type?.hasMacLan &&
                <TextField variant="standard" fullWidth label="MAC LAN" margin="dense" value={material.macLan ?? ""} onChange={e => setMaterial({ ...material, macLan: e.target.value })} />
            }
            {type?.hasMacWlan &&
                <TextField variant="standard" fullWidth label="MAC WLAN" margin="dense" value={material.macWlan ?? ""} onChange={e => setMaterial({ ...material, macWlan: e.target.value })} />
            }

            <DynamsoftScanner open={!!scanner} setOpen={() => setScanner(null)} onScan={handleOnScan} />
        </Fragment>
    );
}