import { Button } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useMemo, useState } from "react";
import { fetchGet, fetchPut, LayoutContext } from "wcz-layout";
import Material, { MaterialOptions } from "../../models/Material";
import { apiUrl } from "../../utils/BaseUrl";
import CustomDialog from "../common/CustomDialog";
import MaterialForm from "./MaterialForm";
import { areObjectsEqual } from "../../utils/Helpers";

const materialOptionsInit: MaterialOptions = { manufacturers: [], partNumbers: [], types: [], models: [], locations: [], currencies: [], companies: [] };

interface MaterialEditFormProps {
    materialId: string,
    setMaterialId: (materialId: string) => void,
    search: () => void
}

export default function MaterialEditForm(props: MaterialEditFormProps) {
    const { materialId, setMaterialId, search } = props;
    const [material, setMaterial] = useState<Material>({} as Material);
    const { t } = useContext(LayoutContext);

    const { data: originalMaterial = {} as Material } = useQuery<Material>(["material", materialId], ({ signal }) => fetchGet(`${apiUrl}/v1/material/${materialId}`, signal), {
        enabled: !!materialId,
        onSuccess: data => setMaterial(data)
    });

    const { data: options = materialOptionsInit } = useQuery<MaterialOptions>(["materialOptions"], ({ signal }) => fetchGet(`${apiUrl}/v1/material/options`, signal), {
        enabled: !!materialId
    });

    const { mutate: update } = useMutation((req: Material) => fetchPut(`${apiUrl}/v1/material/${req.id}`, req), {
        onSuccess: () => { search(); onClose(); }
    });

    const onClose = () => setMaterialId("");

    const updateEnabled: boolean = useMemo(() => {
        const equal = areObjectsEqual(material, originalMaterial);
        return !!material.type?.length && (material.fixAsset ? material.fixAsset.length === 12 : true) && !equal;
    }, [material, originalMaterial]);

    return (
        <CustomDialog open={!!materialId} onClose={onClose} title={material.name} color="primary" actions={[
            <Button key="save" disabled={!updateEnabled} onClick={() => update(material)}>{t("Save")}</Button>]}>
            <MaterialForm material={material} setMaterial={setMaterial} locations={options.locations} options={options} />
        </CustomDialog>
    );
}