import { GroupAdd } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { Fragment, useContext, useState } from "react";
import { LayoutContext } from "wcz-layout";
import { useGetEmployees } from "../../../services/EmployeeService";
import CustomDialog from "../../common/CustomDialog";
import SearchEmployeeCard from "./SearchEmployeeCard";

export default function EmployeesWithNewId() {
    const [open, setOpen] = useState<boolean>(false);
    const { t } = useContext(LayoutContext);

    const { data = [], refetch } = useGetEmployees({
        onSuccess: data => !data.length && setOpen(false)
    }, true);

    return (
        <Fragment>
            <Chip label={`${t("EmployeesWithNewId")} (${data.length})`} icon={<GroupAdd />} onClick={() => setOpen(true)}
                color={data.length ? "info" : "default"} disabled={!data.length} />

            <CustomDialog open={open} onClose={() => setOpen(false)} title={t("EmployeesWithNewId")} color="info" maxWidth="md">
                {data.map(employee => <SearchEmployeeCard employee={employee} key={employee.id} refetch={refetch} />)}
            </CustomDialog>
        </Fragment>
    );
}