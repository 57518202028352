import { Check } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { DataGridPremium, GridColDef, GridRowModel, GridValidRowModel } from "@mui/x-data-grid-premium";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LayoutContext } from "wcz-layout";
import { CellClassName, EditableHeader, GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import CycleCount from "../../models/CycleCount";
import CycleCountItem from "../../models/CycleCountItem";
import { CycleCountItemStatus } from "../../models/enums/CycleCountItemStatus";
import { CycleCountStatus } from "../../models/enums/CycleCountStatus";
import { useUpdateCycleCount } from "../../services/CycleCountService";

interface FinishedCycleCountProps {
    cycleCount: CycleCount;
}

export const FinishedCycleCount: React.FC<FinishedCycleCountProps> = ({ cycleCount }) => {
    const { t, i18n, snackbar } = useContext(LayoutContext);
    const [updatedCycleCount, setUpdatedCycleCount] = useState<CycleCount>({} as CycleCount);
    const navigate = useNavigate();

    useEffect(() => setUpdatedCycleCount(cycleCount), [cycleCount]);

    const columns: GridColDef[] = useMemo(() => [
        { field: 'scannedValue', headerName: t("ScannedValue"), width: 170, },
        { field: 'location', headerName: t("Location"), width: 170, valueGetter: ({ value }) => value.name, },
        {
            field: 'material', headerName: t("Material"), width: 400, valueGetter: ({ value }) => [value?.name, value?.fixAsset, value?.imei, value?.partNumber, value?.serialNumber],
            renderCell: ({ value }) => {
                return (
                    <Box sx={{ my: 1 }}>
                        {value[0] && <Typography>{value[0]}</Typography>}
                        {value[1] && <Typography variant="body2"><b>Fix Asset: </b>{value[1]}</Typography>}
                        {value[2] && <Typography variant="body2"><b>IMEI: </b>{value[2]}</Typography>}
                        {value[3] && <Typography variant="body2"><b>PN: </b>{value[3]}</Typography>}
                        {value[4] && <Typography variant="body2"><b>SN: </b>{value[4]}</Typography>}
                        {!value[0] && !value[1] && !value[2] && !value[3] && !value[4] && <Typography variant="body2">-</Typography>}
                    </Box>
                );
            }
        },
        { field: 'status', headerName: t("Status"), width: 170, type: 'singleSelect', valueOptions: Object.values(CycleCountItemStatus) },
        {
            field: 'expectedQuantity', headerName: t("ExpectedQuantity"), width: 140, type: 'number',
            cellClassName: (params) => params.row.status !== CycleCountItemStatus.Ok ? CellClassName.BackgroundWarning : '',
        },
        {
            field: 'foundQuantity', headerName: t("FoundQuantity"), width: 140, type: 'number',
            cellClassName: (params) => params.row.status !== CycleCountItemStatus.Ok ? CellClassName.BackgroundWarning : '',
        },
        {
            field: 'confirmedQuantity', headerName: t("ConfirmedQuantity"), width: 140, type: 'number',
            editable: true, renderHeader: cycleCount.status === CycleCountStatus.Finished && EditableHeader,
            cellClassName: (params) => params.row.status !== CycleCountItemStatus.Ok ? CellClassName.BackgroundWarning : '',
            renderCell: ({ value, row }) => value ?? <Typography sx={{ opacity: 0.3 }}>{row.foundQuantity}</Typography>
        },
    ] as GridColDef[], [i18n.language, cycleCount]);

    const { mutate: updateCycleCount } = useUpdateCycleCount({
        onSuccess: () => {
            snackbar({ message: t("CycleCountConfirmed") });
            navigate("/cycle-counts");
        }
    });

    const confirmCycleCount = useCallback(() => updateCycleCount({
        ...updatedCycleCount,
        status: CycleCountStatus.Confirmed,
        items: updatedCycleCount.items?.map(d => ({ ...d, confirmedQuantity: d.confirmedQuantity ?? d.foundQuantity }))
    }), [updatedCycleCount]);

    const processRowUpdate = async (row: GridRowModel<GridValidRowModel>): Promise<GridValidRowModel> => {
        setUpdatedCycleCount({
            ...updatedCycleCount,
            items: updatedCycleCount.items?.map(i => i.id === row.id ? row as CycleCountItem : i)
        });

        return row;
    };

    const getRowHeight = useCallback(() => 'auto', []);

    return (
        <TableContainer>
            <DataGridPremium rows={updatedCycleCount.items ?? []} columns={columns} slots={{ toolbar: GridToolbar }} getRowHeight={getRowHeight}
                isCellEditable={props => {
                    switch (props.field) {
                        case 'confirmedQuantity': return cycleCount.status === CycleCountStatus.Finished;
                        default: return false;
                    }
                }}
                editMode="cell" processRowUpdate={processRowUpdate} slotProps={{
                    toolbar: {
                        hideAddRecord: true, items: cycleCount.status === CycleCountStatus.Finished ? [{ title: t('Confirm'), onClick: confirmCycleCount, icon: <Check /> }] : undefined
                    } as GridToolbarProps
                }} />
        </TableContainer>
    );
};