import { List, ListItem, Typography } from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useCallback, useContext, useMemo } from 'react';
import { fetchGet, LayoutContext } from "wcz-layout";
import { GridToolbar, TableContainer } from "wcz-x-data-grid";
import Trail from "../../models/Trail";
import { apiUrl } from "../../utils/BaseUrl";
import CustomDialog from "./CustomDialog";

interface TrailTableProps {
    primaryKey: string,
    setPrimaryKey: (primaryKey: string) => void,
    title: string
}

export const TrailTable: React.FC<TrailTableProps> = ({ primaryKey, setPrimaryKey, title }) => {
    const { t, i18n } = useContext(LayoutContext);

    const { data: trails = [], isLoading } = useQuery<Trail[]>(["trails", primaryKey], ({ signal }) => fetchGet(`${apiUrl}/v1/trail?primaryKey=${primaryKey}`, signal), {
        enabled: !!primaryKey
    });

    const columns: GridColDef[] = useMemo(() => [
        { field: 'dateTime', headerName: t("Date"), width: 200, valueFormatter: ({ value }) => moment(value).formatDateTime() },
        { field: 'userName', headerName: t("UpdatedBy"), width: 150, },
        { field: 'userId', headerName: "ID", width: 150, },
        { field: 'type', headerName: t("Type"), width: 150, },
        {
            field: 'oldValues', headerName: t("OldValues"), width: 500, renderCell: ({ value }) => {
                if (!value) return null;
                const object = JSON.parse(value);
                return <TrailValueList object={object} />;
            }
        },
        {
            field: 'newValues', headerName: t("NewValues"), width: 500, renderCell: ({ value }) => {
                if (!value) return null;
                const object = JSON.parse(value);
                return <TrailValueList object={object} />;
            }
        },
    ] as GridColDef[], [i18n.language]);

    const handleOnClose = useCallback(() => setPrimaryKey(""), []);
    const getRowHeight = useCallback(() => 'auto', []);

    return (
        <CustomDialog open={!!primaryKey} onClose={handleOnClose} title={title} color="info" maxWidth="xl" disablePadding>
            <TableContainer>
                <DataGridPremium rows={trails} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading} getRowHeight={getRowHeight} />
            </TableContainer>
        </CustomDialog>
    );
};

interface ValueListProps {
    object: any
}

export const TrailValueList: React.FC<ValueListProps> = ({ object }) => {
    return (
        <List dense>
            {Object.keys(object).map(key =>
                <ListItem key={key} disablePadding>
                    <Typography variant="body2"><b>{key}: </b> {String(object[key])}</Typography>
                </ListItem>
            )}
        </List>
    );
};