import { Autocomplete, Box, Button, Menu, TextField, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import { LayoutContext, fetchGet, fetchPost, hasRole, newGuid } from "wcz-layout";
import Cart from "../models/Cart";
import CartItem from "../models/CartItem";
import Employee from "../models/Employee";
import Material from "../models/Material";
import { TransferType } from "../models/enums/TransferType";
import { useGetEmployees } from "../services/EmployeeService";
import { itRole } from "../utils/Authorization";
import { apiUrl } from "../utils/BaseUrl";
import { EmployeeStatus } from "../models/enums/EmployeeStatus";

interface EmployeesMenu {
    mouseX: number,
    mouseY: number
}

interface CartContextInterface {
    carts: Cart[],
    refetchCarts: () => void,
    deliverMaterial: (event: React.MouseEvent, material: Material) => void,
    isMaterialInCart: (material: Material) => boolean,
}

export const CartContext = createContext({} as CartContextInterface);

interface CartProviderProps {
    children: ReactNode
}

export function CartProvider(props: CartProviderProps) {
    const { children } = props;
    const [carts, setCarts] = useState<Cart[]>([]);
    const [employeesMenu, setEmployeesMenu] = useState<EmployeesMenu | null>(null);
    const [newItem, setNewItem] = useState<Cart>({ id: newGuid(), type: TransferType.Delivery, employee: null, items: [] });
    const { t } = useContext(LayoutContext);

    const { refetch } = useQuery<Cart[]>(["carts"], ({ signal }) => fetchGet(`${apiUrl}/v1/cart`, signal), {
        enabled: hasRole(itRole),
        refetchOnWindowFocus: hasRole(itRole),
        onSuccess: data => setCarts(data)
    });

    const setFieldFocus = useCallback(() => {
        const field = document.getElementById("cart-keeper");
        field && field.focus();
    }, []);

    const { data: employees = [] } = useGetEmployees({
        enabled: !!employeesMenu,
        onSuccess: setFieldFocus
    }, undefined, EmployeeStatus.Active);

    const deliverMaterial = (event: React.MouseEvent, material: Material) => {
        event.preventDefault();
        setEmployeesMenu({ mouseX: event.clientX, mouseY: event.clientY, });
        setNewItem({ ...newItem, items: [{ id: newGuid(), material: material }] });
    };

    const { mutate: createCart } = useMutation((req: Cart) => fetchPost(`${apiUrl}/v1/cart`, req), {
        onSuccess: () => {
            refetch();
            window.dispatchEvent(new Event('addInCart'));
        },
        onMutate: () => handleMenuClose()
    });

    const { mutate: createCartItem } = useMutation((req: CartItem) => fetchPost(`${apiUrl}/v1/cart/${req.cartId}/item`, req), {
        onSuccess: () => {
            refetch();
            window.dispatchEvent(new Event('addInCart'));
        },
        onMutate: () => handleMenuClose()
    });

    const handleOnSave = () => {
        const cart: Cart | undefined = carts.find(c => c.type === newItem.type && c.employee?.id === newItem.employee?.id);
        if (cart) {
            createCartItem({ cartId: cart.id, material: newItem.items[0].material, id: newGuid() });
        } else {
            createCart(newItem);
        }
    };

    const isMaterialInCart = (material: Material): boolean => carts.some(cart => cart.items.find(i => i.material.id === material.id));

    const handleOnKeeperChange = useCallback((value: Employee | null) => {
        setNewItem({ ...newItem, employee: value });
    }, [newItem]);

    useEffect(() => {
        if (newItem.employee) {
            const saveToCart = document.getElementById("save-to-cart");
            saveToCart && saveToCart.focus();
        }
    }, [newItem.employee]);

    const handleOnTypeChange = useCallback((newType: TransferType) => setNewItem({ ...newItem, type: newType }), [newItem]);

    const handleMenuClose = useCallback(() => {
        setEmployeesMenu(null);
        setNewItem({ id: newGuid(), type: TransferType.Delivery, employee: null, items: [] });
    }, []);

    return (
        <CartContext.Provider value={{ carts: carts, refetchCarts: refetch, deliverMaterial: deliverMaterial, isMaterialInCart: isMaterialInCart }}>
            {children}

            <Menu open={employeesMenu !== null} onClose={handleMenuClose} anchorReference="anchorPosition" variant="menu"
                anchorPosition={employeesMenu !== null ? { top: employeesMenu.mouseY, left: employeesMenu.mouseX } : undefined}>
                <Box sx={{ width: 340, px: 2 }} role="presentation">
                    <Typography variant="h6">{t("AddToCart")}</Typography>

                    <Autocomplete
                        id="cart-keeper"
                        options={employees}
                        value={newItem.employee}
                        getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.id})`}
                        autoHighlight
                        onChange={(e, value) => handleOnKeeperChange(value)}
                        renderInput={(params) => <TextField {...params} label={t("Keeper")} fullWidth variant="standard" margin="normal" required />}
                    />

                    <Autocomplete
                        options={Object.values(TransferType).filter(option => option !== TransferType.Receive)}
                        value={newItem.type ?? null}
                        autoHighlight
                        onChange={(e, value) => handleOnTypeChange(value!)}
                        renderInput={(params) => <TextField {...params} label={t("Type")} fullWidth variant="standard" margin="normal" required />}
                    />

                    <Button id="save-to-cart" variant="contained" onClick={handleOnSave} disabled={!newItem.employee?.id} sx={{ float: "right", mb: 1 }}>{t("Save")}</Button>
                </Box>
            </Menu>
        </CartContext.Provider>
    );
}