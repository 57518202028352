import { isDevelopment } from 'wcz-layout';

let apiUrl = "https://api.dev.wistron.eu/it-eqroom";
let peoplesoftUrl = "https://api.dev.wistron.eu/ps";
let myfaUrl = "https://api.dev.wistron.eu/myfa";
let fileUrl = "https://api.dev.wistron.eu/file";

if (!isDevelopment) {
    apiUrl = "https://api.wistron.eu/it-eqroom";
    peoplesoftUrl = "https://api.wistron.eu/ps";
    myfaUrl = "https://api.wistron.eu/myfa";
    fileUrl = "https://api.wistron.eu/file";
}

//export microservices base URL
export { apiUrl, peoplesoftUrl, myfaUrl, fileUrl };