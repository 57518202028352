import { Category, CloudUpload, Download, History, SwapHoriz } from '@mui/icons-material';
import { styled } from '@mui/material';
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowModes, GridValidRowModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { LoadingButton, LayoutContext, fetchFileGet, fetchPost } from 'wcz-layout';
import { AutocompleteEditInputCell, GridDeleteCellItem, GridToolbar, GridToolbarProps, TableContainer, editInputCellValidation } from 'wcz-x-data-grid';
import HighValueMaterialTooltip from '../components/common/HighValueMaterialTooltip';
import IconTypography from '../components/common/IconTypography';
import MaterialImportHistory from '../components/material/MaterialImportHistory';
import MaterialTrailTable from '../components/material/MaterialTrailTable';
import MaterialTransferDetail from '../components/material/MaterialTransferDetail';
import MaterialTypes from '../components/material/MaterialTypes';
import Material, { MaterialOptions } from '../models/Material';
import { MaterialState } from '../models/enums/MaterialState';
import { useCreateMaterial, useDeleteMaterial, useGetMaterialOptions, useGetMaterials, useUpdateMaterial } from '../services/MaterialService';
import { apiUrl } from '../utils/BaseUrl';
import { keeperGetter, warrantyDateGetter } from '../utils/MaterialHelpers';
import save from 'save-file';

const materialOptionsInit: MaterialOptions = { manufacturers: [], partNumbers: [], types: [], models: [], locations: [], currencies: [], companies: [] };

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function Materials() {
    const { changeTitle, t, i18n, snackbar } = useContext(LayoutContext);
    const apiRef = useGridApiRef();
    const [transferMaterialId, setTransfersMaterialId] = useState<string>("");
    const [trailMaterialId, setTrailMaterialId] = useState<string>("");
    const [typesOpen, setTypesOpen] = useState<boolean>(false);
    const [importedMaterials, setImportedMaterials] = useState<Material[]>([]);

    const { data: materials = [], isLoading } = useGetMaterials();
    const { data: options = materialOptionsInit, refetch: refetchOptions } = useGetMaterialOptions();

    useEffect(() => changeTitle(t("Material")), [i18n.language]);

    const create = useCreateMaterial();
    const update = useUpdateMaterial();
    const remove = useDeleteMaterial();

    const handleTransfersClick = useCallback((id: string) => () => setTransfersMaterialId(id), []);
    const handleTrailClick = useCallback((id: string) => () => setTrailMaterialId(id), []);

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'id', type: 'actions', width: 50,
            getActions: (params: any) => {
                const isInEditMode = apiRef.current.getRowMode(params.id) === GridRowModes.Edit;
                if (isInEditMode && params.row.isNew)
                    return [<GridDeleteCellItem key="remove" id={params.id} />];
                else
                    return [
                        <GridActionsCellItem key="transfers" icon={<SwapHoriz />} label={t("Transfers")} onClick={handleTransfersClick(params.id)} showInMenu />,
                        <GridActionsCellItem key="trail" icon={<History />} label={t("History")} onClick={handleTrailClick(params.id)} showInMenu />,
                        <GridDeleteCellItem key="remove" id={params.id} remove={remove} showInMenu />
                    ];
            }
        },
        { field: 'company', headerName: t("Company"), editable: true, width: 120, renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.companies} freeSolo /> },
        { field: 'keeper', headerName: t("Keeper"), width: 250, valueGetter: keeperGetter },
        {
            field: 'type', headerName: t("Type"), editable: true, width: 150, preProcessEditCellProps: editInputCellValidation.hasLength,
            renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.types.map(t => t.name)} />
        },
        { field: 'manufacturer', headerName: t("Manufacturer"), width: 150, editable: true, renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.manufacturers} freeSolo /> },
        { field: 'model', headerName: "Model", width: 220, editable: true, renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.models} freeSolo /> },
        { field: 'hostname', headerName: t("Hostname"), editable: true, width: 200, },
        { field: 'serialNumber', headerName: "SN", editable: true, width: 170, renderHeader: () => <IconTypography fontWeight={600} endIcon={<HighValueMaterialTooltip sx={{ ml: 1 }} />}>SN</IconTypography>, },
        { field: 'fixAsset', headerName: "Fix Asset", width: 160, editable: true, renderHeader: () => <IconTypography fontWeight={600} endIcon={<HighValueMaterialTooltip sx={{ ml: 1 }} />}>Fix Asset</IconTypography>, },
        { field: 'imei', headerName: "IMEI", width: 170, editable: true, renderHeader: () => <IconTypography fontWeight={600} endIcon={<HighValueMaterialTooltip sx={{ ml: 1 }} />}>IMEI</IconTypography>, },
        { field: 'location', headerName: t("Location"), editable: true, width: 220, valueGetter: ({ value }) => value?.name, renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.locations.map(l => l.name)} /> },
        { field: 'department', headerName: t("Department"), editable: true, width: 120, },
        { field: 'partNumber', headerName: "PN", editable: true, width: 170, renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.partNumbers} freeSolo /> },
        { field: 'orderDate', headerName: t("OrderDate"), width: 150, editable: true, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'monthsOfWarranty', headerName: t("Warranty"), editable: true, type: 'number', renderCell: warrantyDateGetter },
        { field: 'state', headerName: t("State"), width: 150, editable: true, type: 'singleSelect', valueOptions: Object.values(MaterialState) },
        { field: 'underRepairTo', headerName: t("UnderRepairTo"), width: 150, editable: true, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'price', headerName: t("Price"), width: 120, editable: true, type: 'number' },
        { field: 'currency', headerName: t("Currency"), width: 120, editable: true, renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.currencies} /> },
        { field: 'itId', headerName: "IT ID", editable: true, width: 150 },
        { field: 'stockQuantity', headerName: t("StockQuantity"), editable: true, width: 190, type: 'number' },
        { field: 'safetyStock', headerName: t("SafetyStock"), editable: true, width: 190, type: 'number' },
        { field: 'remark', headerName: t("Remark"), editable: true, width: 350, },
        { field: 'ip', headerName: "IP", editable: true, width: 150, },
        { field: 'macLan', headerName: "MAC LAN", editable: true, width: 250, },
        { field: 'macWlan', headerName: "MAC WLAN", editable: true, width: 250, },
        { field: 'deviceId', headerName: t("DeviceId"), editable: true, width: 350, },
    ] as GridColDef[], [options, i18n.language]);

    const processRowUpdate = useCallback(async (row: GridValidRowModel): Promise<GridValidRowModel> => {
        const newMaterial: Material = row as Material;

        if (newMaterial.fixAsset) {
            newMaterial.fixAsset = newMaterial.fixAsset.toUpperCase();
            const companyCode: string = newMaterial.fixAsset.substring(0, 4);
            if (companyCode !== "L090" && companyCode !== "L520") {
                snackbar({ message: t("IncorrectCompanyCode"), severity: "warning" });
                throw new Error();
            }
        }

        const locationName: string | null = row.location;
        if (locationName)
            newMaterial.location = options.locations.find(l => l.name === locationName)!;

        if (row.isNew)
            await create.mutateAsync(newMaterial);
        else {
            newMaterial.keeper = materials.find(m => m.id === row.id)!.keeper;
            await update.mutateAsync(newMaterial);
        }

        return { ...newMaterial, isNew: false };
    }, [options, materials]);

    const { mutate: importData, isLoading: isImporting } = useMutation((req: FormData) => fetchPost(`${apiUrl}/v1/material/import`, req), {
        onSuccess: (data: Material[]) => {
            setImportedMaterials(data);
            refetchOptions();
        }
    });

    const handleExportTemplate = useCallback(() => {
        fetchFileGet(`${apiUrl}/v1/material/exportTemplate`, undefined)
            .then(data => save(data, "material-import"));
    }, []);

    const handleImport = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files?.length) return;

        let formData: FormData = new FormData();
        formData.append('file', e.target.files[0]);
        importData(formData);
    }, []);

    return (
        <Fragment>
            <TableContainer>
                <DataGridPremium rows={materials} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading} apiRef={apiRef}
                    editMode="row" processRowUpdate={processRowUpdate}
                    slotProps={{
                        toolbar: {
                            items: [
                                { title: t("Types"), icon: <Category />, onClick: () => setTypesOpen(true) },
                                { title: t("Template"), icon: <Download />, onClick: handleExportTemplate },
                                <LoadingButton key="import" component="label" size="small" startIcon={<CloudUpload />} disabled={isImporting}>
                                    Import
                                    <VisuallyHiddenInput type="file" onChange={handleImport} accept=".xlsx,.xls" />
                                </LoadingButton>
                            ], viewKey: "materials", export: true
                        } as GridToolbarProps
                    }} />
            </TableContainer>

            <MaterialTransferDetail materialId={transferMaterialId} setMaterialId={setTransfersMaterialId} />
            <MaterialTypes open={typesOpen} setOpen={setTypesOpen} refetchOptions={refetchOptions} />
            <MaterialTrailTable primaryKey={trailMaterialId} setPrimaryKey={setTrailMaterialId} />
            <MaterialImportHistory materials={importedMaterials} setMaterials={setImportedMaterials} />
        </Fragment>
    );
}