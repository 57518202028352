import { useQuery } from "@tanstack/react-query";
import { fetchGet } from "wcz-layout";
import Location from "../../models/Location";
import { apiUrl } from "../../utils/BaseUrl";
import { TrailTable } from "../common/TrailTable";

interface LocationTrailTableProps {
    primaryKey: string,
    setPrimaryKey: (primaryKey: string) => void
}

export default function LocationTrailTable(props:  LocationTrailTableProps) {
    const { primaryKey, setPrimaryKey } = props;

    const { data: location = {} as Location } = useQuery<Location>(["location", primaryKey], ({ signal }) => fetchGet(`${apiUrl}/v1/location/${primaryKey}`, signal), {
        enabled: !!primaryKey
    });

    return <TrailTable primaryKey={primaryKey} setPrimaryKey={setPrimaryKey} title={location.name} />;
}