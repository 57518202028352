import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { LicenseInfo } from '@mui/x-data-grid-premium';
import packageJson from '../package.json';
import { BarcodeReader } from "dynamsoft-javascript-barcode";

LicenseInfo.setLicenseKey(packageJson.MuiXLicence);
BarcodeReader.license = packageJson.DynamsoftLicence;
BarcodeReader.engineResourcePath = `https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@${packageJson.dependencies['dynamsoft-javascript-barcode']}/dist/`;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

serviceWorkerRegistration.register();