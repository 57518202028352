import { ProductionQuantityLimits } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Fragment, useContext, useState } from "react";
import { fetchPost, LayoutContext } from "wcz-layout";
import PaginationFilter from "../../../models/base/PaginationFilter";
import PaginationResponse from "../../../models/base/PaginationResponse";
import Material, { MaterialOptions } from "../../../models/Material";
import { apiUrl } from "../../../utils/BaseUrl";
import CustomDialog from "../../common/CustomDialog";
import SearchMaterialCard from "./SearchMaterialCard";

const initPaginationFilter: PaginationFilter = {
    advancedFilter: {
        logic: "and",
        filters: [
            {
                field: "safetyStock",
                operator: "neq",
                value: null
            },
        ]
    }
};

interface MaterialWithoutQuantityProps {
    options: MaterialOptions
}

export default function MaterialWithoutQuantity(props: MaterialWithoutQuantityProps) {
    const { options } = props;
    const [open, setOpen] = useState<boolean>(false);
    const [data, setData] = useState<Material[]>([]);
    const { t } = useContext(LayoutContext);

    const { refetch } = useQuery<PaginationResponse<Material>>(["materials", "withoutQuantity"], () => fetchPost(`${apiUrl}/v1/material/search`, initPaginationFilter), {
        onSuccess: content => {
            const materialWithLowStockQuantity: Material[] = content.data.filter(material => material.stockQuantity < material.safetyStock!);
            if (!materialWithLowStockQuantity.length)
                setOpen(false);

            setData(materialWithLowStockQuantity);
        }
    });

    return (
        <Fragment>
            <Chip label={`${t("LowStockQuantity")} (${data.length})`} icon={<ProductionQuantityLimits />} onClick={() => setOpen(true)}
                color={data.length ? "warning" : "default"} disabled={!data.length} />

            <CustomDialog open={open} onClose={() => setOpen(false)} title={t("WithoutLocation")} color="warning" maxWidth="md">
                {data.map(material => <SearchMaterialCard material={material} key={material.id} refetch={refetch} options={options} />)}
            </CustomDialog>
        </Fragment>
    );
}