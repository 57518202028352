import { History, Laptop, SwapHoriz } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchGet, hasRole, LayoutContext } from 'wcz-layout';
import { DataGridPremium, GridActionsCellItem, GridCellParams, GridColDef, GridRowModel, GridValidRowModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { AutocompleteEditInputCell, editInputCellValidation, GridDeleteCellItem, GridToolbar, GridToolbarProps, TableContainer } from 'wcz-x-data-grid';
import EmployeeDetail from '../components/employee/EmployeeDetail';
import EmployeeTrailTable from '../components/employee/EmployeeTrailTable';
import Employee from '../models/Employee';
import { EmployeeStatus } from '../models/enums/EmployeeStatus';
import { useCreateEmployee, useDeleteEmployee, useGetEmployees, useUpdateEmployee } from '../services/EmployeeService';
import { itRole } from '../utils/Authorization';
import { peoplesoftUrl } from '../utils/BaseUrl';
import EmployeeTransferDetail from '../components/employee/EmployeeTransferDetail';

export default function Employees() {
    const { department } = useParams();
    const { changeTitle, t, i18n, user } = useContext(LayoutContext);
    const [employeeDetailId, setEmployeeDetailId] = useState<string>("");
    const [trailEmployeeId, setTrailEmployeeId] = useState<string>("");
    const [employeeTransferId, setEmployeeTransferId] = useState<string>("");
    const apiRef = useGridApiRef();

    const { data: employees = [], isLoading } = useGetEmployees();

    const { data: departments } = useQuery(["departments"], ({ signal }) => fetchGet(`${peoplesoftUrl}/v1/department/all`, signal), {
        select: (data) => data.map((i: any) => i.departmentId).sort(),
    });

    useEffect(() => changeTitle(t("Employees")), [i18n.language]);

    const { mutateAsync: create } = useCreateEmployee();
    const { mutateAsync: update } = useUpdateEmployee();
    const remove = useDeleteEmployee();

    useEffect(() => {
        if (department)
            setFilterModel();
        else
            clearFilterModel();

    }, [department]);

    const setFilterModel = useCallback(() => {
        apiRef.current.setFilterModel({
            items: [
                { field: 'department', operator: 'contains', value: department }
            ]
        });
    }, [apiRef]);

    const clearFilterModel = useCallback(() => {
        apiRef.current.setFilterModel({ items: [] });
    }, [apiRef]);

    const handleTransfersClick = useCallback((id: string) => () => setEmployeeTransferId(id), []);

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'menu', type: 'actions', width: 50,
            getActions: (params: any) => {
                if (hasRole(itRole))
                    return [
                        <GridActionsCellItem key="materials" icon={<Laptop />} label={t("Materials")} onClick={() => setEmployeeDetailId(params.id)} showInMenu />,
                        <GridActionsCellItem key="transfers" icon={<SwapHoriz />} label={t("Transfers")} onClick={handleTransfersClick(params.id)} showInMenu />,
                        <GridActionsCellItem key="history" icon={<History />} label={t("History")} onClick={() => setTrailEmployeeId(params.id)} showInMenu />,
                        <GridDeleteCellItem key="remove" id={params.id} remove={remove} showInMenu />
                    ];

                return [<GridActionsCellItem key="materials" icon={<Laptop />} label={t("Materials")} onClick={() => setEmployeeDetailId(params.id)} showInMenu />];
            },
        },
        { field: 'firstName', headerName: t("FirstName"), width: 130, editable: hasRole(itRole), preProcessEditCellProps: editInputCellValidation.hasLength, },
        { field: 'lastName', headerName: t("LastName"), width: 130, editable: hasRole(itRole), preProcessEditCellProps: editInputCellValidation.hasLength, },
        { field: 'id', headerName: "ID", width: 150, editable: hasRole(itRole), preProcessEditCellProps: editInputCellValidation.hasLength, },
        {
            field: 'department', headerName: t("Department"), width: 150, editable: hasRole(itRole),
            preProcessEditCellProps: editInputCellValidation.hasLength, renderEditCell: params => <AutocompleteEditInputCell params={params} options={departments} freeSolo />
        },
        { field: 'company', headerName: t("Company"), width: 150, editable: hasRole(itRole), type: 'singleSelect', valueOptions: ["WCZ", "WSCZ"] },
        { field: 'status', headerName: "Status", width: 150, type: 'singleSelect', valueOptions: Object.keys(EmployeeStatus) },
    ] as GridColDef[], [i18n.language, departments, user]);

    const processRowUpdate = async (row: GridRowModel<GridValidRowModel>): Promise<GridValidRowModel> => {
        if (row.isNew) {
            const newEmployee: Employee = { ...row as Employee, id: row.id.toUpperCase(), shouldBeInactivated: false };
            await create(newEmployee);
        }
        else
            await update(row as Employee);

        return { ...row, isNew: false };
    };

    const isCellEditable = useCallback((params: GridCellParams) => {
        if (!hasRole(itRole))
            return false;

        switch (params.field) {
            case 'firstName': return true;
            case 'lastName': return true;
            case 'id': return params.row.isNew;
            case 'department': return true;
            case 'company': return true;
        }
    }, [user]);

    return (
        <Fragment>
            <TableContainer>
                <DataGridPremium rows={employees} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading} apiRef={apiRef}
                    editMode="row" processRowUpdate={processRowUpdate} isCellEditable={isCellEditable} slotProps={{
                        toolbar: { export: true, } as GridToolbarProps
                    }} />
            </TableContainer>

            <EmployeeDetail employeeId={employeeDetailId} setEmployeeId={setEmployeeDetailId} />
            <EmployeeTrailTable primaryKey={trailEmployeeId} setPrimaryKey={setTrailEmployeeId} />
            <EmployeeTransferDetail employeeId={employeeTransferId} setEmployeeId={setEmployeeTransferId} />
        </Fragment>
    );
}