import { Create, Laptop, LocationOn } from "@mui/icons-material";
import { Card, CardHeader, Typography } from "@mui/material";
import { Fragment, useContext, useState } from "react";
import { LayoutContext } from "wcz-layout";
import Location from "../../../models/Location";
import LocationForm from "../../location/LocationForm";
import LocationMaterials from "../../location/LocationMaterials";
import CardActionItems from "./common/CardActionItems";

interface SearchLocationCardProps {
    location: Location,
    refetch: () => void
}

export default function SearchLocationCard(props: SearchLocationCardProps) {
    const { location, refetch } = props;
    const [editId, setEditId] = useState<string>("");
    const [materialLocationId, setMaterialLocationId] = useState<string>("");
    const { t } = useContext(LayoutContext);

    return (
        <Card variant="outlined" sx={{ mt: 1 }}>
            <CardHeader
                avatar={<LocationOn color="error" />}
                title={<Typography variant="body1"><b>{t("Name")}: </b>{location.name}</Typography>}
                action={
                    <CardActionItems items={[
                        { label: t("Edit"), onClick: () => setEditId(location.id), icon: <Create /> },
                        { label: t("Material"), onClick: () => setMaterialLocationId(location.id), icon: <Laptop /> }
                    ]} />
                }
                subheader={
                    <Fragment>
                        {location.building && <Typography variant="body2" color="text.secondary"><b>{t("Building")}: </b>{location.building}</Typography>}
                        {location.equipmentRoom && <Typography variant="body2" color="text.secondary"><b>{t("EquipmentRoom")}: </b>{location.equipmentRoom}</Typography>}
                        {location.rack && <Typography variant="body2" color="text.secondary"><b>{t("Rack")}: </b>{location.rack}</Typography>}
                        {location.shelf && <Typography variant="body2" color="text.secondary"><b>{t("Shelf")}: </b>{location.shelf}</Typography>}
                    </Fragment>
                }
            />
            <LocationForm locationId={editId} setLocationId={setEditId} search={refetch} />
            <LocationMaterials locationId={materialLocationId} setLocationId={setMaterialLocationId} />
        </Card>
    );
}