import { Typography } from "@mui/material";
import { Fragment } from "react";
import Material from "../../models/Material";

interface MaterialDetailProps {
    material: Material
}

export default function MaterialDetail(props: MaterialDetailProps) {
    const { material } = props;

    return (
        <Fragment>
            <Typography variant="subtitle2"><b>{material.name}</b></Typography>
            {material.serialNumber && <Typography variant="subtitle2"><b>SN: </b>{material.serialNumber}</Typography>}
            {material.imei && <Typography variant="subtitle2"><b> IMEI: </b>{material.imei}</Typography>}
            {material.fixAsset && <Typography variant="subtitle2"><b>Fix Asset: </b>{material.fixAsset}</Typography>}
        </Fragment>
    );
}