import { Button, CircularProgress, SxProps, Theme } from '@mui/material';
import React from 'react';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';

interface LoadingButtonProps {
    onClick: () => void,
    children?: React.ReactNode,
    variant?: 'text' | 'outlined' | 'contained',
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    disabled?: boolean,
    fullWidth?: boolean,
    sx?: SxProps<Theme>,
    startIcon?: React.ReactNode,
    endIcon?: React.ReactNode,
}

export default function LoadingButton(props: LoadingButtonProps) {
    const { onClick, children, variant, color, disabled, fullWidth, sx, startIcon, endIcon } = props;
    const isFetching = !!useIsFetching();
    const isMutating = !!useIsMutating();

    return (
        <Button variant={variant} color={color} disabled={isFetching || isMutating || disabled} onClick={onClick} fullWidth={fullWidth} sx={sx} startIcon={startIcon} endIcon={endIcon}>
            {(isFetching || isMutating) ? <CircularProgress size={24} color="success" /> : children}
        </Button>
    );
}