import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import moment from "moment";
import { useCallback, useContext, useMemo } from "react";
import { LayoutContext } from "wcz-layout";
import { GridToolbar, TableContainer } from "wcz-x-data-grid";
import Material from "../../models/Material";
import { MaterialState } from "../../models/enums/MaterialState";
import { warrantyDateGetter } from "../../utils/MaterialHelpers";
import CustomDialog from "../common/CustomDialog";

interface MaterialImportHistoryProps {
    materials: Material[],
    setMaterials: (materials: Material[]) => void
}

export default function MaterialImportHistory(props: MaterialImportHistoryProps) {
    const { materials, setMaterials } = props;
    const { t } = useContext(LayoutContext);

    const columns: GridColDef[] = useMemo(() => [
        { field: 'company', headerName: t("Company"), width: 120, },
        { field: 'type', headerName: t("Type"), width: 150, },
        { field: 'manufacturer', headerName: t("Manufacturer"), width: 150, },
        { field: 'model', headerName: "Model", width: 220, },
        { field: 'hostname', headerName: t("Hostname"), width: 200, },
        { field: 'serialNumber', headerName: "SN", width: 170, },
        { field: 'fixAsset', headerName: "Fix Asset", width: 160 },
        { field: 'imei', headerName: "IMEI", width: 170 },
        { field: 'location', headerName: t("Location"), width: 220, valueGetter: ({ value }) => value?.name, },
        { field: 'partNumber', headerName: "PN", width: 170, },
        { field: 'orderDate', headerName: t("OrderDate"), width: 150, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'monthsOfWarranty', headerName: t("Warranty"), type: 'number', renderCell: warrantyDateGetter },
        { field: 'state', headerName: t("State"), width: 150, type: 'singleSelect', valueOptions: Object.values(MaterialState) },
        { field: 'underRepairTo', headerName: t("UnderRepairTo"), width: 150, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'price', headerName: t("Price"), width: 120, type: 'number' },
        { field: 'currency', headerName: t("Currency"), width: 120, },
        { field: 'itId', headerName: "IT ID", width: 150 },
        { field: 'stockQuantity', headerName: t("StockQuantity"), width: 190, type: 'number' },
        { field: 'safetyStock', headerName: t("SafetyStock"), width: 190, type: 'number' },
        { field: 'remark', headerName: t("Remark"), width: 350, },
        { field: 'ip', headerName: "IP", width: 150, },
        { field: 'macLan', headerName: "MAC LAN", width: 250, },
        { field: 'macWlan', headerName: "MAC WLAN", width: 250, },
    ] as GridColDef[], []);

    const onClose = useCallback(() => setMaterials([]), []);

    return (
        <CustomDialog onClose={onClose} open={!!materials.length} title="Import" color="primary" maxWidth="xl" disablePadding>
            <TableContainer height="80vh">
                <DataGridPremium rows={materials} columns={columns} slots={{ toolbar: GridToolbar }} />
            </TableContainer>
        </CustomDialog>
    );
}