import { Add, AddShoppingCart, AdUnits, Create, DevicesOther, KeyboardReturn, Laptop, Monitor, PhoneAndroid, Print, Remove, Save, SimCard, SwapHoriz, Tablet } from "@mui/icons-material";
import { Button, Card, CardActions, CardHeader, Grid, IconButton, TextField, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { fetchPut, LayoutContext } from "wcz-layout";
import { CartContext } from "../../../contexts/CartContext";
import { MaterialState } from "../../../models/enums/MaterialState";
import Material, { MaterialOptions } from "../../../models/Material";
import { apiUrl } from "../../../utils/BaseUrl";
import { getWarrantyDate } from "../../../utils/MaterialHelpers";
import ReceiveMaterialMenu, { ReceiveMaterialMenuModel } from "../../common/ReceiveMaterialMenu";
import MaterialDetail from "../../material/MaterialDetail";
import MaterialEditForm from "../../material/MaterialEditForm";
import MaterialTransferDetail from "../../material/MaterialTransferDetail";
import CardActionItems from "./common/CardActionItems";
import { DeviceIdButton } from "./searchMaterialCard/DeviceIdButton";

interface SearchMaterialCardProps {
    material: Material,
    refetch: () => void,
    options: MaterialOptions
}

export default function SearchMaterialCard(props: SearchMaterialCardProps) {
    const { material, refetch, options } = props;
    const [editId, setEditId] = useState<string>("");
    const [transferMaterialId, setTransferMaterialId] = useState<string>("");
    const [expanded, setExpanded] = useState<boolean>(false);
    const [receiveMaterialMenu, setReceiveMaterialMenu] = useState<ReceiveMaterialMenuModel | null>(null);
    const [deductQuantity, setDeductQuantity] = useState<number>(1);
    const { carts, deliverMaterial } = useContext(CartContext);
    const { t, snackbar } = useContext(LayoutContext);
    const type = useMemo(() => options.types.find(t => t.name === material.type), [material, options]);

    useEffect(() => {
        window.addEventListener('addInCart', onAddInCart);
        return () => window.removeEventListener('addInCart', onAddInCart);
        // eslint-disable-next-line
    }, []);

    const onAddInCart = () => refetch();

    const { mutate: updateMaterial } = useMutation((req: Material) => fetchPut(`${apiUrl}/v1/material/${req.id}`, req), {
        onSuccess: () => {
            refetch();
            setDeductQuantity(1);
        }
    });

    const handleDeductQuantity = () => {
        updateMaterial({
            ...material,
            stockQuantity: material.stockQuantity - deductQuantity
        });

        snackbar({ message: t("QuantityDeducted", { quantity: deductQuantity }) });
    };

    const deductQuantityOnEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && deductQuantity && canBeDeducted) {
            handleDeductQuantity();
        }
    };

    const getIcon = () => {
        switch (material.type) {
            case "Notebook": return <Laptop color="success" />;
            case "Desktop": return <AdUnits fill="1" color="success" />;
            case "Monitor": return <Monitor color="success" />;
            case "Tablet": return <Tablet color="success" />;
            case "Mobile": return <PhoneAndroid color="success" />;
            case "Printer": return <Print color="success" />;
            case "SIM card": return <SimCard color="success" />;
            case "E-SIM card": return <SimCard color="success" />;
            default: return <DevicesOther color="success" />;
        }
    };

    const getStateColor: string = useMemo(() => {
        switch (material.state) {
            case MaterialState.Reserved: return "info.main";
            case MaterialState.Sold: return "warning.main";
            case MaterialState.UnderRepair: return "warning.main";
            case MaterialState.NotUsable: return "error.main";
            case MaterialState.Lost: return "error.main";
            case MaterialState.Scrap: return "error.main";
            default: return "text.secondary";
        }
    }, [material.state]);

    const canBeOrdered: boolean = useMemo(() => (!!material.stockQuantity && (material.state === MaterialState.New || material.state === MaterialState.Usable)), [material, carts]);

    const canBeReturned: boolean = useMemo(() => material.state === MaterialState.InUse, [material.state]);

    const canBeDeducted: boolean = useMemo(() => (material.stockQuantity > 0 && deductQuantity <= material.stockQuantity), [material, deductQuantity]);

    const reservedBy = useMemo(() => carts.find(c => c.items.find(i => i.material.id === material.id))?.employee, [material, carts]);

    return (
        <Card variant="outlined" sx={{ mt: 1 }}>
            <CardHeader
                avatar={getIcon()}
                title={<MaterialDetail material={material} />}
                action={
                    <CardActionItems items={[
                        { label: t("Edit"), onClick: () => setEditId(material.id), icon: <Create /> },
                        { label: t("Transfers"), onClick: () => setTransferMaterialId(material.id), icon: <SwapHoriz /> }
                    ]} />
                }
                subheader={
                    <Fragment>
                        {material.location?.id && <Typography variant="subtitle2" color="text.secondary"><b>{t("Location")}: </b>{material.location.name}</Typography>}
                        {!!material.keeper?.id && <Typography variant="subtitle2" color="text.secondary"><b>{t("Keeper")}: </b>{`${material.keeper.firstName} ${material.keeper.lastName} (${material.keeper.id})`}</Typography>}
                        {!type?.requireHandover && <Typography variant="subtitle2" color="text.secondary"><b>{t("StockQuantity")}: </b>{material.stockQuantity}</Typography>}
                        {material.state && <Typography variant="subtitle2" color={getStateColor}><b>{t("State")}: </b>{material.state}</Typography>}
                        {reservedBy && <Typography variant="subtitle2" color={getStateColor}><b>{t("ReservedBy")}: </b>{`${reservedBy.firstName} ${reservedBy.lastName} (${reservedBy.id})`}</Typography>}
                        {expanded &&
                            <Fragment>
                                {material.partNumber && <Typography variant="subtitle2" color="text.secondary"><b>PN: </b>{material.partNumber}</Typography>}
                                {material.company && <Typography variant="subtitle2" color="text.secondary"><b>{t("Company")}: </b>{material.company}</Typography>}
                                {material.hostname && <Typography variant="subtitle2" color="text.secondary"><b>Hostname: </b>{material.hostname}</Typography>}
                                {material.orderDate && <Typography variant="subtitle2" color="text.secondary"><b>{t("OrderDate")}: </b>{moment(material.orderDate).formatDate()}</Typography>}
                                {(!!material.monthsOfWarranty && material.orderDate) && <Typography variant="subtitle2" color="text.secondary"><b>{t("Warranty")}: </b>{getWarrantyDate(material.orderDate, material.monthsOfWarranty)}</Typography>}
                                {!!material.price && <Typography variant="subtitle2" color="text.secondary"><b>{t("Price")}: </b>{material.price} {material.currency}</Typography>}
                                {material.imei && <Typography variant="subtitle2" color="text.secondary"><b>IMEI: </b>{material.imei}</Typography>}
                                {material.itId && <Typography variant="subtitle2" color="text.secondary"><b>IT ID: </b>{material.itId}</Typography>}
                            </Fragment>
                        }
                    </Fragment>
                }
            />

            <CardActions>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Button size="small" startIcon={expanded ? <Remove /> : <Add />} onClick={() => setExpanded(!expanded)}>{t(expanded ? "Less" : "More")}</Button>
                    </Grid>

                    {type?.requireHandover ?
                        <Grid item>
                            {type.hasDeviceId && <DeviceIdButton material={material} search={refetch} />}
                            {canBeReturned && <Button size="small" startIcon={<KeyboardReturn />} onClick={e => setReceiveMaterialMenu({ mouseX: e.clientX, mouseY: e.clientY, material: material })}>{t("Receive")}</Button>}
                            {canBeOrdered && <Button size="small" startIcon={<AddShoppingCart />} onClick={e => deliverMaterial(e, material)}>{t("Handover")}</Button>}
                        </Grid>
                        :
                        <Grid item>
                            <TextField size="small" value={deductQuantity} label={t("DeductQuantity")} onChange={e => setDeductQuantity(Number(e.target.value))} type="number" onKeyDown={deductQuantityOnEnter} InputProps={{
                                endAdornment: <IconButton size="small" onClick={handleDeductQuantity} disabled={!canBeDeducted} sx={{ ml: 1 }}><Save /></IconButton>
                            }} />
                        </Grid>
                    }
                </Grid>
            </CardActions>

            <MaterialEditForm materialId={editId} setMaterialId={setEditId} search={refetch} />
            <MaterialTransferDetail materialId={transferMaterialId} setMaterialId={setTransferMaterialId} />
            <ReceiveMaterialMenu menu={receiveMaterialMenu} setMenu={setReceiveMaterialMenu} refetch={refetch} />
        </Card>
    );
}