import { QrCode, History } from "@mui/icons-material";
import { Autocomplete, Box, IconButton, TextField } from "@mui/material";
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { isAndroid, isMobile, LayoutContext, TypographyWithIcon } from "wcz-layout";
import SearchModel from "../../../models/SearchModel";
import DynamsoftScanner from "../../common/DynamsoftScanner";
import { useGetEmployeeSearchHistories } from "../../../services/EmployeeSearchHistoryService";

interface SearchInputProps {
    data: SearchModel,
    searchText: string,
    setSearchText: (searchText: string) => void,
    loading: boolean,
}

export default function SearchInput(props: SearchInputProps) {
    const { data, searchText, setSearchText, loading } = props;
    const [options, setOptions] = useState<string[]>([]);
    const [scannerOpen, setScannerOpen] = useState<boolean>(false);
    const { t } = useContext(LayoutContext);
    const showHistory = useMemo(() => !data.employees?.length && !data.locations?.length && !data.materials?.length, [data]);

    const { data: employeeSearchHistories = [] } = useGetEmployeeSearchHistories();

    useEffect(() => {
        if (showHistory && employeeSearchHistories.length)
            setOptions(employeeSearchHistories.map(esh => esh.searchText));
        else
            initializeOptions();
    }, [data, showHistory, employeeSearchHistories]);

    const initializeOptions = useCallback(() => {
        const materialNames = Array.from(new Set((data.materials ?? []).filter(row => !!row.name).map(row => row.name)));
        const materialTypes = Array.from(new Set((data.materials ?? []).filter(row => !!row.type).map(row => row.type)));
        const materialManufacturers = Array.from(new Set((data.materials ?? []).filter(row => !!row.manufacturer).map(row => row.manufacturer)));
        const materialPartNumbers = Array.from(new Set((data.materials ?? []).filter(row => !!row.partNumber).map(row => row.partNumber)));
        const materialSerialNumbers = Array.from(new Set((data.materials ?? []).filter(row => !!row.serialNumber).map(row => row.serialNumber)));
        const materialFixAssets = Array.from(new Set((data.materials ?? []).filter(row => !!row.fixAsset).map(row => row.fixAsset!)));
        const materialModels = Array.from(new Set((data.materials ?? []).filter(row => !!row.model).map(row => row.model)));
        const materialImeis = Array.from(new Set((data.materials ?? []).filter(row => !!row.imei).map(row => row.imei)));
        const materialHostnames = Array.from(new Set((data.materials ?? []).filter(row => !!row.hostname).map(row => row.hostname)));

        const fullNamesWithFirstNameFirst = Array.from(new Set((data.employees ?? []).filter(row => !!row.firstName && !!row.lastName).map(row => `${row.firstName} ${row.lastName}`)));
        const fullNamesWithLastNameFirst = Array.from(new Set((data.employees ?? []).filter(row => !!row.firstName && !!row.lastName).map(row => `${row.lastName} ${row.firstName}`)));
        const employeeIds = Array.from(new Set((data.employees ?? []).filter(row => !!row.id).map(row => row.id)));
        const employeeDepartments = Array.from(new Set((data.employees ?? []).filter(row => !!row.department).map(row => row.department)));

        const locationNames = Array.from(new Set((data.locations ?? []).filter(row => !!row.name).map(row => row.name)));

        const allOptions = materialNames
            .concat(materialTypes)
            .concat(materialManufacturers)
            .concat(materialPartNumbers)
            .concat(materialSerialNumbers)
            .concat(materialFixAssets)
            .concat(materialModels)
            .concat(materialImeis)
            .concat(materialHostnames)
            .concat(fullNamesWithFirstNameFirst)
            .concat(fullNamesWithLastNameFirst)
            .concat(employeeIds)
            .concat(employeeDepartments)
            .concat(locationNames);

        setOptions(allOptions);
    }, [data]);

    const handleOnInputChange = useCallback((value: string, reason?: string) => {
        if (reason === "clear")
            return setSearchText("");

        setSearchText(value);
    }, []);

    const handleOnScan = (value: string) => {
        setSearchText(value);
    };

    return (
        <Fragment>
            <Autocomplete
                value={searchText}
                options={options}
                autoHighlight
                autoComplete
                clearOnEscape
                freeSolo
                loading={loading}
                blurOnSelect
                onInputChange={(e, value, reason) => handleOnInputChange(value, reason)}
                renderInput={(params) =>
                    <TextField {...params} placeholder={t("Search") ?? undefined} autoFocus={!isAndroid}
                        InputProps={{
                            ...params.InputProps,

                            startAdornment: (
                                <Fragment>
                                    {isMobile ?
                                        <IconButton onClick={() => setScannerOpen(true)}>
                                            <QrCode />
                                        </IconButton>
                                        :
                                        params.InputProps.startAdornment
                                    }
                                </Fragment>
                            ),
                        }}
                    />
                }
                renderOption={(props, option) => (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                        <TypographyWithIcon startIcon={showHistory && <History />}>{option}</TypographyWithIcon>
                    </Box>
                )}
            />

            <DynamsoftScanner open={scannerOpen} setOpen={setScannerOpen} onScan={handleOnScan} />
        </Fragment>
    );
}