import { useQuery } from "@tanstack/react-query";
import { fetchGet } from "wcz-layout";
import Employee from "../../models/Employee";
import { apiUrl } from "../../utils/BaseUrl";
import { TrailTable } from "../common/TrailTable";

interface EmployeeTrailTableProps {
    primaryKey: string,
    setPrimaryKey: (primaryKey: string) => void
}

export default function EmployeeTrailTable(props: EmployeeTrailTableProps) {
    const { primaryKey, setPrimaryKey } = props;

    const { data: employee = {} as Employee } = useQuery<Employee>(["employee", primaryKey], ({ signal }) => fetchGet(`${apiUrl}/v1/employee/${primaryKey}`, signal), {
        enabled: !!primaryKey
    });

    return <TrailTable primaryKey={primaryKey} setPrimaryKey={setPrimaryKey} title={`${employee.firstName} ${employee.lastName}`} />;
}