import { Autocomplete, Box, Button, Divider, Menu, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useContext, useState } from "react";
import { fetchGet, fetchPost, LayoutContext, newGuid } from "wcz-layout";
import { TransferType } from "../../../models/enums/TransferType";
import Handover from "../../../models/Handover";
import Location from "../../../models/Location";
import Material from "../../../models/Material";
import { apiUrl } from "../../../utils/BaseUrl";
import Employee from "../../../models/Employee";
import { useGetEmployees } from "../../../services/EmployeeService";
import { CartContext } from "../../../contexts/CartContext";
import Cart from "../../../models/Cart";
import CartItem from "../../../models/CartItem";

export interface ReceiveAllMaterialMenuModel {
    mouseX: number,
    mouseY: number,
    materials: Material[]
}

interface ReceiveAllMaterialMenuProps {
    menu: ReceiveAllMaterialMenuModel | null,
    setMenu: (menu: ReceiveAllMaterialMenuModel | null) => void,
    refetch: () => void
}

export default function ReceiveAllMaterialMenu(props: ReceiveAllMaterialMenuProps) {
    const { menu, setMenu, refetch } = props;
    const [location, setLocation] = useState<Location | null>(null);
    const [tab, setTab] = useState<number>(0);
    const [employee, setEmployee] = useState<Employee | null>(null);
    const { t } = useContext(LayoutContext);
    const { carts } = useContext(CartContext);
    const open = Boolean(menu);

    const { data: locations = [] } = useQuery<Location[]>(["locations"], ({ signal }) => fetchGet(`${apiUrl}/v1/location`, signal), {
        enabled: tab === 0 && open
    });

    const { data: employees = [] } = useGetEmployees({
        enabled: tab === 1 && open
    });

    const { mutateAsync: createHandover } = useMutation((req: Handover) => fetchPost(`${apiUrl}/v1/handover`, req));

    const { mutateAsync: createCart } = useMutation((req: Cart) => fetchPost(`${apiUrl}/v1/cart`, req), {
        onSuccess: () => {
            refetch();
            handleMenuClose();
            window.dispatchEvent(new Event('addInCart'));
        },
    });

    const { mutateAsync: createCartItem } = useMutation((req: CartItem) => fetchPost(`${apiUrl}/v1/cart/${req.cartId}/item`, req));

    const receiveMaterials = () => {
        const createHandovers = menu?.materials.map(material =>
            createHandover({
                id: newGuid(),
                type: TransferType.Receive,
                keeper: material.keeper!,
                items: [{
                    id: newGuid(),
                    material: { ...material, location: location ?? undefined }
                }]
            })
        );

        Promise.all(createHandovers!).then(() => {
            if (employee) {
                const cart: Cart | undefined = carts.find(c => c.type === TransferType.Delivery && c.employee?.id === employee?.id);
                if (cart) {
                    const createdCartItems = menu?.materials.map(material => createCartItem({ cartId: cart.id, material: material, id: newGuid() }));
                    Promise.all(createdCartItems!).then(() => {
                        refetch();
                        handleMenuClose();
                        window.dispatchEvent(new Event('addInCart'));
                    });
                } else {
                    createCart({
                        id: newGuid(),
                        type: TransferType.Delivery,
                        employee: employee,
                        items: menu!.materials.map(material => ({ id: newGuid(), material: material }))
                    });
                }
            } else {
                refetch();
                handleMenuClose();
            }
        });
    };

    const handleMenuClose = () => {
        setMenu(null);
        setLocation(null);
    };

    const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);

        if (newValue === 0)
            setEmployee(null);
        else if (newValue === 1)
            setLocation(null);
    }, []);

    return (
        <Box>
            <Menu open={open} onClose={handleMenuClose} anchorReference="anchorPosition" variant="menu"
                anchorPosition={menu !== null ? { top: menu.mouseY, left: menu.mouseX } : undefined}>
                <Box sx={{ width: 340, px: 2 }} role="presentation">
                    <Typography variant="h6">{t("ReceiveAll")}</Typography>
                    <Typography variant="body2">{t("AllNotSoldMaterialsWillBeReceived")}</Typography>

                    <Divider sx={{ my: 1 }} />

                    <Tabs value={tab} onChange={handleTabChange} centered>
                        <Tab label={t("Location")} />
                        <Tab label={t("Employee")} />
                    </Tabs>

                    {tab === 0 &&
                        <Autocomplete
                            options={locations}
                            getOptionLabel={o => o.name}
                            value={location}
                            autoHighlight
                            onChange={(e, value) => setLocation(value)}
                            renderInput={(params) => <TextField {...params} label={t("Locations")} fullWidth variant="standard" margin="normal" required autoFocus />}
                        />
                    }

                    {tab === 1 &&
                        <Autocomplete
                            options={employees}
                            value={employee}
                            getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.id})`}
                            autoHighlight
                            onChange={(e, value) => setEmployee(value)}
                            renderInput={(params) => <TextField {...params} label={t("Keeper")} fullWidth variant="standard" margin="normal" required autoFocus />}
                        />
                    }

                    <Button variant="contained" onClick={receiveMaterials} sx={{ float: "right", mb: 1 }}>{t("Save")}</Button>
                </Box>
            </Menu>
        </Box>
    );
}