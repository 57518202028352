import { AddShoppingCart, KeyboardReturn } from "@mui/icons-material";
import { DataGridPremium, GridActionsCellItem, GridColDef, GridValidRowModel } from "@mui/x-data-grid-premium";
import moment from "moment";
import { Fragment, useContext, useMemo, useState } from "react";
import { hasRole, LayoutContext } from "wcz-layout";
import { AutocompleteEditInputCell, editInputCellValidation, GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import { CartContext } from "../../contexts/CartContext";
import { MaterialState } from "../../models/enums/MaterialState";
import Location from "../../models/Location";
import Material, { MaterialOptions } from "../../models/Material";
import { useGetLocation } from "../../services/LocationService";
import { useCreateMaterial, useGetMaterialOptions, useUpdateMaterial } from "../../services/MaterialService";
import { itRole } from "../../utils/Authorization";
import { keeperGetter, warrantyDateGetter } from "../../utils/MaterialHelpers";
import CustomDialog from "../common/CustomDialog";
import HighValueMaterialTooltip from "../common/HighValueMaterialTooltip";
import IconTypography from "../common/IconTypography";
import ReceiveMaterialMenu, { ReceiveMaterialMenuModel } from "../common/ReceiveMaterialMenu";

const materialOptionsInit: MaterialOptions = { manufacturers: [], partNumbers: [], types: [], models: [], locations: [], currencies: [], companies: [] };

interface LocationMaterialsProps {
    locationId: string,
    setLocationId: (locationId: string) => void
}

export default function LocatiotnMaterials(props: LocationMaterialsProps) {
    const { locationId, setLocationId } = props;
    const { deliverMaterial, isMaterialInCart, carts } = useContext(CartContext);
    const [receiveMaterialMenu, setReceiveMaterialMenu] = useState<ReceiveMaterialMenuModel | null>(null);
    const { t, i18n, snackbar } = useContext(LayoutContext);

    const { data: location = {} as Location, refetch, isLoading } = useGetLocation(locationId, {
        enabled: !!locationId
    });

    const { data: options = materialOptionsInit } = useGetMaterialOptions({
        enabled: hasRole(itRole)
    });

    const create = useCreateMaterial();
    const update = useUpdateMaterial();

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'actions', type: 'actions', width: 50,
            getActions: (params: any) => {
                const material: Material = params.row;
                const canBeOrdered: boolean = !!material.stockQuantity && !material.keeper?.id && (material.state !== MaterialState.Sold && material.state !== MaterialState.NotUsable && material.state !== MaterialState.UnderRepair) && !isMaterialInCart(material);
                const canBeReturned: boolean = !!material.keeper?.id && material.state !== MaterialState.Sold && !isMaterialInCart(material);

                if (canBeReturned) return [<GridActionsCellItem key="receive" icon={<KeyboardReturn color="primary" />} label={t("Receive")} onClick={(e: any) => setReceiveMaterialMenu({ mouseX: e.clientX, mouseY: e.clientY, material: material })} showInMenu />];
                if (canBeOrdered) return [<GridActionsCellItem key="handover" icon={<AddShoppingCart color="primary" />} label={t("Handover")} onClick={(e: any) => deliverMaterial(e, material)} showInMenu />];
                return [];
            }
        },
        { field: 'company', headerName: t("Company"), editable: true, width: 120, renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.companies} freeSolo /> },
        { field: 'keeper', headerName: t("Keeper"), width: 250, valueGetter: keeperGetter },
        {
            field: 'type', headerName: t("Type"), editable: true, width: 150, preProcessEditCellProps: editInputCellValidation.hasLength,
            renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.types.map(t => t.name)} />
        },
        { field: 'manufacturer', headerName: t("Manufacturer"), width: 150, editable: true, renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.manufacturers} freeSolo /> },
        { field: 'model', headerName: "Model", width: 220, editable: true, renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.models} freeSolo /> },
        { field: 'hostname', headerName: t("Hostname"), editable: true, width: 200, },
        { field: 'serialNumber', headerName: "SN", editable: true, width: 170, },
        { field: 'fixAsset', headerName: "Fix Asset", width: 160, editable: true, renderHeader: () => <IconTypography endIcon={<HighValueMaterialTooltip sx={{ ml: 1, fontWeight: '600' }} />}>Fix Asset</IconTypography> },
        { field: 'imei', headerName: "IMEI", width: 170, editable: true, renderHeader: () => <IconTypography endIcon={<HighValueMaterialTooltip sx={{ ml: 1, fontWeight: '600' }} />}>IMEI</IconTypography> },
        { field: 'partNumber', headerName: "PN", editable: true, width: 170, renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.partNumbers} freeSolo /> },
        { field: 'orderDate', headerName: t("OrderDate"), width: 150, editable: true, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'monthsOfWarranty', headerName: t("Warranty"), editable: true, type: 'number', renderCell: warrantyDateGetter },
        { field: 'state', headerName: t("State"), width: 150, editable: true, type: 'singleSelect', valueOptions: Object.values(MaterialState) },
        { field: 'underRepairTo', headerName: t("UnderRepairTo"), width: 150, editable: true, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'price', headerName: t("Price"), width: 120, editable: true, type: 'number' },
        { field: 'currency', headerName: t("Currency"), width: 120, editable: true, renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.currencies} /> },
        { field: 'itId', headerName: "IT ID", editable: true, width: 150 },
        { field: 'stockQuantity', headerName: t("StockQuantity"), editable: true, width: 190, type: 'number' },
        { field: 'safetyStock', headerName: t("SafetyStock"), editable: true, width: 190, type: 'number' },
        { field: 'remark', headerName: t("Remark"), editable: true, width: 350, },
        { field: 'ip', headerName: "IP", editable: true, width: 150, },
        { field: 'macLan', headerName: "MAC LAN", editable: true, width: 250, },
        { field: 'macWlan', headerName: "MAC WLAN", editable: true, width: 250, },
    ] as GridColDef[], [i18n.language, carts]);

    const processRowUpdate = async (row: GridValidRowModel): Promise<GridValidRowModel> => {
        const newMaterial: Material = row as Material;

        if (newMaterial.fixAsset) {
            newMaterial.fixAsset = newMaterial.fixAsset.toUpperCase();
            const companyCode: string = newMaterial.fixAsset.substring(0, 4);
            if (companyCode !== "L090" && companyCode !== "L520") {
                snackbar({ message: t("IncorrectCompanyCode"), severity: "warning" });
                throw new Error();
            }
        }

        if (row.isNew)
            await create.mutateAsync(newMaterial);
        else {
            newMaterial.keeper = location.materials?.find(m => m.id === row.id)!.keeper;
            await update.mutateAsync(newMaterial);
        }

        return { ...newMaterial, isNew: false };
    };

    return (
        <Fragment>
            <CustomDialog open={!!locationId} onClose={() => setLocationId("")} title={location.name} color="info" maxWidth="xl" disablePadding>
                <TableContainer>
                    <DataGridPremium rows={location.materials ?? []} columns={columns} components={{ Toolbar: GridToolbar }} loading={isLoading}
                        editMode="row" processRowUpdate={processRowUpdate}
                        componentsProps={{ toolbar: { hideAddRecord: true, export: true } as GridToolbarProps }} />
                </TableContainer>
            </CustomDialog>

            <ReceiveMaterialMenu menu={receiveMaterialMenu} setMenu={setReceiveMaterialMenu} refetch={refetch} />
        </Fragment>
    );
}