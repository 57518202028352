import { useContext } from "react";
import { useParams } from "react-router-dom";
import { LayoutContext } from "wcz-layout";
import { FinishedCycleCount } from "../components/cycleCount/FinishedCycleCount";
import { StartedCycleCount } from "../components/cycleCount/StartedCycleCount";
import CycleCount from "../models/CycleCount";
import { CycleCountStatus } from "../models/enums/CycleCountStatus";
import { useGetCycleCount } from "../services/CycleCountService";

export default function CycleCountDetail() {
    const { id } = useParams();
    const { changeTitle, t } = useContext(LayoutContext);

    const { data: cycleCount = {} as CycleCount } = useGetCycleCount(id, {
        onSuccess: data => changeTitle(t(data.name ? data.name : "CycleCount"))
    });

    if (cycleCount.status === CycleCountStatus.Started)
        return <StartedCycleCount cycleCount={cycleCount}/>;

    if (cycleCount.status === CycleCountStatus.Finished || cycleCount.status === CycleCountStatus.Confirmed)
        return <FinishedCycleCount cycleCount={cycleCount} />;

    return null;
}