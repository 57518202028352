import { VerifiedUser } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import { Fragment, useContext, useMemo, useState } from "react";
import { Dialog, LayoutContext, fetchPut, isMobile } from "wcz-layout";
import Material from "../../../../models/Material";
import DynamsoftScanner from "../../../common/DynamsoftScanner";
import { useMutation } from "@tanstack/react-query";
import { apiUrl } from "../../../../utils/BaseUrl";

interface DeviceIdButtonProps {
    material: Material;
    search: () => void;
}

export const DeviceIdButton: React.FC<DeviceIdButtonProps> = ({ material, search }) => {
    const { t } = useContext(LayoutContext);
    const [open, setOpen] = useState<boolean>(false);
    const [deviceId, setDeviceId] = useState<string | undefined>(material.deviceId);
    const registered = useMemo(() => !!material.deviceId, [material]);

    const onOpen = () => {
        setOpen(true);
        if (!isMobile) {            
            setTimeout(() => {
                const element = document.getElementById("device-id") as HTMLInputElement | null;
                element?.select();
            }, 300);
        }
    };

    const onClose = () => setOpen(false);

    const { mutate: update } = useMutation((req: Material) => fetchPut(`${apiUrl}/v1/material/${req.id}`, req), {
        onSuccess: () => { search(); onClose(); }
    });

    const onScan = (value: string) => update({ ...material, deviceId: value });

    const handleUpdate = () => update({ ...material, deviceId: deviceId });

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            handleUpdate();
        }
    };

    return (
        <Fragment>
            <Button size="small" color={registered ? "success" : undefined} startIcon={<VerifiedUser />} onClick={onOpen}>{t(registered ? "Registered" : "Register")}</Button>

            {isMobile ?
                <DynamsoftScanner open={open} setOpen={setOpen} onScan={onScan} />
                :
                <Dialog open={open} onClose={onClose} title={t("RegisterDevice")} actions={[
                    <Button key="save" onClick={handleUpdate}>{t("Save")}</Button>]}
                >
                    <TextField id="device-id" variant="standard" fullWidth label={t("DeviceId")} margin="dense" value={deviceId ?? ""}
                        onChange={e => setDeviceId(e.target.value ?? undefined)} onKeyDown={handleOnKeyDown} />
                </Dialog>
            }
        </Fragment>
    );
};