import { Autocomplete, Box, Button, Menu, TextField, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useContext, useMemo, useState } from "react";
import { fetchGet, fetchPost, LayoutContext, newGuid } from "wcz-layout";
import Cart from "../../models/Cart";
import CartItem from "../../models/CartItem";
import Employee from "../../models/Employee";
import { TransferType } from "../../models/enums/TransferType";
import Handover from "../../models/Handover";
import Location from "../../models/Location";
import Material from "../../models/Material";
import { useGetEmployees } from "../../services/EmployeeService";
import { apiUrl } from "../../utils/BaseUrl";

export interface ReceiveMaterialMenuModel {
    mouseX: number,
    mouseY: number,
    material: Material
}

interface ReceiveMaterialMenuProps {
    menu: ReceiveMaterialMenuModel | null,
    setMenu: (menu: ReceiveMaterialMenuModel | null) => void,
    refetch: () => void
}

interface ReceiveOption {
    id: string;
    value: string;
}

export default function ReceiveMaterialMenu(props: ReceiveMaterialMenuProps) {
    const { menu, setMenu, refetch } = props;
    const [receiveOption, setReceiveOption] = useState<ReceiveOption | null>(null);
    const { t } = useContext(LayoutContext);
    const open = Boolean(menu);

    const { data: locations = [] } = useQuery<Location[]>(["locations"], ({ signal }) => fetchGet(`${apiUrl}/v1/location`, signal), { enabled: open });
    const { data: employees = [] } = useGetEmployees({ enabled: open });

    const data = useMemo(() => {
        const locationData = locations.map(l => ({ id: l.id, value: l.name }));
        const employeeData = employees.map(e => ({ id: e.id, value: `${e.firstName} ${e.lastName} (${e.id})` }));
        return [...locationData, ...employeeData];
    }, [locations, employees]);

    const { mutateAsync: createHandoverAsync } = useMutation((req: Handover) => fetchPost(`${apiUrl}/v1/handover`, req));

    const receiveMaterial = useCallback(() => {
        const location = locations.find(l => l.id === receiveOption?.id);

        createHandoverAsync({
            id: newGuid(),
            type: TransferType.Receive,
            keeper: menu!.material.keeper!,
            items: [{
                id: newGuid(),
                material: { ...menu!.material, location: location }
            }]
        }).then(() => {
            const employee = employees.find(e => e.id === receiveOption?.id);
            if (employee) {
                addToCart(employee);
            } else {
                refetch();
                handleMenuClose();
            }
        });
    }, [locations, menu, receiveOption]);

    const { data: carts = [] } = useQuery<Cart[]>(["carts"], ({ signal }) => fetchGet(`${apiUrl}/v1/cart`, signal), { enabled: open });

    const { mutate: createCart } = useMutation((req: Cart) => fetchPost(`${apiUrl}/v1/cart`, req), {
        onSuccess: () => {
            window.dispatchEvent(new Event('addInCart'));
            refetch();
            handleMenuClose();
        }
    });

    const { mutate: createCartItem } = useMutation((req: CartItem) => fetchPost(`${apiUrl}/v1/cart/${req.cartId}/item`, req), {
        onSuccess: () => {
            window.dispatchEvent(new Event('addInCart'));
            refetch();
            handleMenuClose();
        }
    });

    const addToCart = useCallback((employee: Employee) => {
        const cart: Cart | undefined = carts.find(c => c.type === TransferType.Delivery && c.employee?.id === employee.id);
        if (cart)
            createCartItem({ cartId: cart.id, material: menu!.material, id: newGuid() });
        else
            createCart({ id: newGuid(), employee: employee, type: TransferType.Delivery, items: [{ id: newGuid(), material: menu!.material }] });
    }, [carts, menu]);

    const handleMenuClose = useCallback(() => {
        setMenu(null);
        setReceiveOption(null);
    }, []);

    return (
        <Box>
            <Menu open={open} onClose={handleMenuClose} anchorReference="anchorPosition" variant="menu"
                anchorPosition={menu !== null ? { top: menu.mouseY, left: menu.mouseX } : undefined}>
                <Box sx={{ width: 340, px: 2 }} role="presentation">
                    <Typography variant="h6">{t("Receive")}</Typography>

                    <Autocomplete
                        options={data}
                        getOptionLabel={o => o.value}
                        value={receiveOption}
                        autoHighlight
                        onChange={(e, value) => setReceiveOption(value)}
                        renderInput={(params) => <TextField {...params} fullWidth variant="standard" margin="normal" required autoFocus />}
                    />

                    <Button variant="contained" onClick={receiveMaterial} sx={{ float: "right", my: 1 }}>{t("Save")}</Button>
                </Box>
            </Menu>
        </Box>
    );
}