import { Info } from "@mui/icons-material";
import { SxProps, Theme, Tooltip } from "@mui/material";
import { useContext } from "react";
import { LayoutContext } from "wcz-layout";

const getTitle = (language: string) => language === "cs" ? "Při vyplněném Fixassetu, SN nebo IMEI se po vydání nastaví majitel." : "If the Fixasset, SN or IMEI is filled in, the keeper will be set after handover";

interface HighValueMaterialTooltipProps {
    sx?: SxProps<Theme>
}

export default function HighValueMaterialTooltip(props: HighValueMaterialTooltipProps) {
    const { sx } = props;
    const { i18n } = useContext(LayoutContext);

    return (
        <Tooltip title={getTitle(i18n.language)}>
            <Info sx={sx} color="primary" />
        </Tooltip>
    );
}