import { DataGridPremium, GridColDef, GridRowId, GridRowModel } from "@mui/x-data-grid-premium";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useMemo } from "react";
import { fetchDelete, fetchGet, fetchPost, fetchPut, LayoutContext } from "wcz-layout";
import { GridDeleteCellItem, GridToolbar, TableContainer } from "wcz-x-data-grid";
import MaterialType from "../../models/MaterialType";
import { apiUrl } from "../../utils/BaseUrl";
import CustomDialog from "../common/CustomDialog";

interface MaterialTypesProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    refetchOptions: () => void
}

export default function MaterialTypes(props: MaterialTypesProps) {
    const { open, setOpen, refetchOptions } = props;
    const { t } = useContext(LayoutContext);

    const { data: materialTypes = [], isLoading } = useQuery<MaterialType[]>(["materialTypes"], ({ signal }) => fetchGet(`${apiUrl}/v1/materialType`, signal), {
        enabled: open
    });

    const create = useMutation((req: MaterialType) => fetchPost(`${apiUrl}/v1/materialType`, req), { onSuccess: () => refetchOptions() });
    const update = useMutation((req: MaterialType) => fetchPut(`${apiUrl}/v1/materialType/${req.id}`, req), { onSuccess: () => refetchOptions() });
    const remove = useMutation((id: GridRowId) => fetchDelete(`${apiUrl}/v1/materialType/${id}`), { onSuccess: () => refetchOptions() });

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'id', type: 'actions', width: 50,
            getActions: (params: any) => [<GridDeleteCellItem key="remove" id={params.id} remove={remove} />]
        },
        { field: 'name', headerName: t("Name"), editable: true, width: 200, },
        { field: 'hasManufacturer', headerName: t("Manufacturer"), editable: true, width: 120, type: "boolean" },
        { field: 'hasPartNumber', headerName: "PN", editable: true, width: 120, type: "boolean" },
        { field: 'hasSerialNumber', headerName: "SN", editable: true, width: 120, type: "boolean" },
        { field: 'hasFixAsset', headerName: "Fix Asset", editable: true, width: 120, type: "boolean" },
        { field: 'hasModel', headerName: "Model", editable: true, width: 120, type: "boolean" },
        { field: 'hasImei', headerName: "IMEI", editable: true, width: 120, type: "boolean" },
        { field: 'hasOrderDate', headerName: t("OrderDate"), editable: true, width: 120, type: "boolean" },
        { field: 'hasMonthsOfWarranty', headerName: t("Warranty"), editable: true, width: 120, type: "boolean" },
        { field: 'hasState', headerName: t("State"), editable: true, width: 120, type: "boolean" },
        { field: 'hasUnderRepairTo', headerName: t("UnderRepairTo"), editable: true, width: 120, type: "boolean" },
        { field: 'hasPrice', headerName: t("Price"), editable: true, width: 120, type: "boolean" },
        { field: 'hasCurrency', headerName: t("Currency"), editable: true, width: 120, type: "boolean" },
        { field: 'hasItId', headerName: "IT ID", editable: true, width: 120, type: "boolean" },
        { field: 'hasStockQuantity', headerName: t("StockQuantity"), editable: true, width: 120, type: "boolean" },
        { field: 'hasSafetyStock', headerName: t("SafetyStock"), editable: true, width: 120, type: "boolean" },
        { field: 'hasLocation', headerName: t("Location"), editable: true, width: 120, type: "boolean" },
        { field: 'hasDepartment', headerName: t("Department"), editable: true, width: 120, type: "boolean" },
        { field: 'hasHostname', headerName: t("Hostname"), editable: true, width: 120, type: "boolean" },
        { field: 'hasRemark', headerName: t("Remark"), editable: true, width: 120, type: "boolean" },
        { field: 'hasCompany', headerName: t("Company"), editable: true, width: 120, type: "boolean" },
        { field: 'requireHandover', headerName: t("Handover"), editable: true, width: 120, type: "boolean" },
        { field: 'requireSignature', headerName: t("Signature"), editable: true, width: 120, type: "boolean" },
        { field: 'hasIp', headerName: "IP", editable: true, width: 120, type: "boolean" },
        { field: 'hasMacLan', headerName: "MAC LAN", editable: true, width: 120, type: "boolean" },
        { field: 'hasMacWlan', headerName: "MAC WLAN", editable: true, width: 120, type: "boolean" },
        { field: 'hasDeviceId', headerName: t("DeviceId"), editable: true, width: 120, type: "boolean" },
        { field: 'requireLocation', headerName: t("RequireLocation"), editable: true, width: 150, type: "boolean" },
    ] as GridColDef[], []);

    const processRowUpdate = async (row: GridRowModel<MaterialType>): Promise<MaterialType> => {
        if (row.isNew)
            create.mutate(row);
        else {
            update.mutate(row);
        }

        return { ...row, isNew: false };
    };


    return (
        <CustomDialog onClose={() => setOpen(false)} open={open} title={t("Types")} color="primary" maxWidth="xl" disablePadding>
            <TableContainer height="80vh">
                <DataGridPremium rows={materialTypes} columns={columns} components={{ Toolbar: GridToolbar }} loading={isLoading}
                    editMode="row" processRowUpdate={processRowUpdate}
                    initialState={{ pinnedColumns: { left: ['name'] } }}
                />
            </TableContainer>
        </CustomDialog>
    );
}