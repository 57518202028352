import { useQuery } from "@tanstack/react-query";
import { fetchGet } from "wcz-layout";
import Material from "../../models/Material";
import { apiUrl } from "../../utils/BaseUrl";
import { TrailTable } from "../common/TrailTable";

interface MaterialTrailTableProps {
    primaryKey: string,
    setPrimaryKey: (primaryKey: string) => void
}

export default function MaterialTrailTable(props: MaterialTrailTableProps) {
    const { primaryKey, setPrimaryKey } = props;

    const { data: material = {} as Material } = useQuery<Material>(["material", primaryKey], ({ signal }) => fetchGet(`${apiUrl}/v1/material/${primaryKey}`, signal), {
        enabled: !!primaryKey
    });

    return <TrailTable primaryKey={primaryKey} setPrimaryKey={setPrimaryKey} title={material.name} />;
}