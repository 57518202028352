import { PersonOff } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Fragment, useContext, useState } from "react";
import { fetchPost, LayoutContext } from "wcz-layout";
import PaginationFilter from "../../../models/base/PaginationFilter";
import PaginationResponse from "../../../models/base/PaginationResponse";
import Material, { MaterialOptions } from "../../../models/Material";
import { apiUrl } from "../../../utils/BaseUrl";
import CustomDialog from "../../common/CustomDialog";
import SearchMaterialCard from "./SearchMaterialCard";

const initPaginationFilter: PaginationFilter = {
    advancedFilter: {
        logic: "and",
        filters: [
            {
                field: "keeper.status",
                operator: "eq",
                value: "Terminated"
            }
        ]
    }
};

interface MaterialOfInactiveEmployeesProps {
    options: MaterialOptions
}

export default function MaterialOfInactiveEmployees(props: MaterialOfInactiveEmployeesProps) {
    const { options } = props;
    const [open, setOpen] = useState<boolean>(false);
    const { t } = useContext(LayoutContext);

    const { data = {} as PaginationResponse<Material>, refetch } = useQuery<PaginationResponse<Material>>(["materials", "terminated"], () => fetchPost(`${apiUrl}/v1/material/search`, initPaginationFilter), {
        onSuccess: content => {
            if (!content.data.length)
                setOpen(false);
        }
    });

    return (
        <Fragment>
            <Chip label={`${t("Inactive")} (${data.data?.length ?? 0})`} icon={<PersonOff />} onClick={() => setOpen(true)}
                color={data.data?.length ? "secondary" : "default"} disabled={!data.data?.length} />

            <CustomDialog open={open} onClose={() => setOpen(false)} title={t("Inactive")} color="secondary" maxWidth="md">
                {data.data?.map(material => <SearchMaterialCard material={material} key={material.id} refetch={refetch} options={options} />)}
            </CustomDialog>
        </Fragment>
    );
}