import { GridRowId } from "@mui/x-data-grid-premium";
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import { trimEnd } from "lodash";
import { useCallback, useContext } from "react";
import { fetchDelete, fetchGet, fetchPost, fetchPut, hasRole, LayoutContext } from "wcz-layout";
import PaginationFilter from "../models/base/PaginationFilter";
import PaginationResponse from "../models/base/PaginationResponse";
import Employee from "../models/Employee";
import { itRole } from "../utils/Authorization";
import { apiUrl } from "../utils/BaseUrl";
import { EmployeeStatus } from "../models/enums/EmployeeStatus";

const queryKey: string = "Employee";

export function useGetEmployees<TQueryFnData = Employee[], TError = string, TData = TQueryFnData>(options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn' | 'initialData'>, onlyWithPreviousEmployeeId?: boolean, status?: EmployeeStatus) {
    const { user } = useContext(LayoutContext);

    const getParams = useCallback(() => {
        const params = new URLSearchParams();

        if (!hasRole(itRole))
            params.append("department", trimEnd(user.department, "0"));

        if (onlyWithPreviousEmployeeId)
            params.append("onlyWithPreviousEmployeeId", "true");

        if (status)
            params.append("status", status);

        return params;
    }, [user, status, onlyWithPreviousEmployeeId]);

    return useQuery([queryKey, onlyWithPreviousEmployeeId, status], ({ signal }) => fetchGet(`${apiUrl}/v1/employee?${getParams()}`, signal), options);
}

export function useGetEmployee<TQueryFnData = Employee, TError = string, TData = TQueryFnData>(id: GridRowId, options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn' | 'initialData'>) {
    return useQuery([queryKey, id], ({ signal }) => fetchGet(`${apiUrl}/v1/${queryKey}/${id}`, signal), options);
}

export function useServerSideEmployees<TQueryFnData = PaginationResponse<Employee>, TError = string, TData = TQueryFnData>(key: string, data: PaginationFilter, options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn' | 'initialData'>) {
    return useQuery([queryKey, key], () => fetchPost(`${apiUrl}/${queryKey}/search`, data), options);
}

interface UseCreateOptions {
    onSuccess?: (data: Employee) => void,
    onError?: (message: string) => void,
}

export function useCreateEmployee(options?: UseCreateOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((model: Employee) => fetchPost(`${apiUrl}/v1/${queryKey}`, model), {
        onMutate: async (model) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });
            await queryClient.cancelQueries({ queryKey: [queryKey, model.id] });

            const previousDataList = queryClient.getQueryData<Employee[]>([queryKey]);
            if (previousDataList)
                queryClient.setQueryData([queryKey], () => [model, ...previousDataList]);

            const previousData = queryClient.getQueryData<Employee>([queryKey, model.id]);
            if (previousData)
                queryClient.setQueryData([queryKey, model.id], () => model);

            return { previousDataList, previousData };
        },
        onError: (err: string, model, context) => {
            if (context) {
                queryClient.setQueryData([queryKey], context.previousDataList);
                queryClient.setQueryData([queryKey, model.id], context.previousData);
            }
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: (model, variables, context) => {
            if (context) {
                queryClient.setQueryData([queryKey], [model, ...context.previousDataList ?? []]);
                queryClient.setQueryData([queryKey, variables.id], model);
            }
            if (options?.onSuccess)
                options.onSuccess(model);
        },
    });
}

interface UseUpdateOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useUpdateEmployee(options?: UseUpdateOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((model: Employee) => fetchPut(`${apiUrl}/v1/${queryKey}/${model.id}`, model), {
        onMutate: async (model) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });
            await queryClient.cancelQueries({ queryKey: [queryKey, model.id] });

            const previousDataList = queryClient.getQueryData<Employee[]>([queryKey]);
            if (previousDataList)
                queryClient.setQueryData([queryKey], previousDataList.map(prev => prev.id === model.id ? model : prev));

            const previousData = queryClient.getQueryData<Employee>([queryKey, model.id]);
            if (previousData)
                queryClient.setQueryData([queryKey, model.id], model);

            return { previousDataList, previousData };
        },
        onError: (err: string, variables, context) => {
            if (context) {
                queryClient.setQueryData([queryKey], context.previousDataList);
                queryClient.setQueryData([queryKey, variables.id], context.previousData);
            }
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: () => {
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};

interface UseDeleteOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useDeleteEmployee(options?: UseDeleteOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((id: GridRowId) => fetchDelete(`${apiUrl}/v1/${queryKey}/${id}`), {
        onMutate: async (id) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });

            const previousData = queryClient.getQueryData<Employee[]>([queryKey]);

            if (previousData) {
                queryClient.setQueryData([queryKey], previousData.filter(prev => prev.id !== id));

                return { previousData };
            }
        },
        onError: (err: string, id, context) => {
            if (context)
                queryClient.setQueryData([queryKey], context.previousData);
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: () => {
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};