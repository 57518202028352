import { Close, ScreenRotation } from "@mui/icons-material";
import { AppBar, Backdrop, Button, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import SignaturePad from 'react-signature-pad-wrapper';
import { Upload as TusUpload } from 'tus-js-client';
import { getToken, LayoutContext, LayoutSnackbar } from "wcz-layout";
import { fileUrl } from "../../utils/BaseUrl";
import LoadingButton from "./LoadingButton";

interface SignatureDialogProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    handoverId: string,
    onSuccess: () => void
}

export default function SignatureDialog(props: SignatureDialogProps) {
    const { open, setOpen, handoverId, onSuccess } = props;
    const [signaturePad, setSignaturePad] = useState<SignaturePad | null>(null);
    const { user, t } = useContext(LayoutContext);
    const [isLandscape, setIsLandscape] = useState<boolean>(true);

    useEffect(() => {
        if (open) {
            handleOnOrientationChange();
            window.addEventListener('orientationchange', handleOnOrientationChange);
        }

        return () => window.removeEventListener('orientationchange', handleOnOrientationChange);
        // eslint-disable-next-line
    }, [open]);

    const handleOnOrientationChange = () => {
        setTimeout(() => setIsLandscape(window.matchMedia("(orientation: landscape)").matches), 300);
    };

    const handleUpload = async (file: File) => {
        if (!handoverId)
            return;

        // Create a new tus upload
        const upload = new TusUpload(file, {
            endpoint: fileUrl + "/v1/upload",
            chunkSize: 1048576, //1 MB                
            metadata: {
                appName: window.location.hostname,
                subId: handoverId,
                filename: file.name,
                filetype: file.type
            },
            headers: { "Authorization": `Bearer ${await getToken()}` },
            onError: (error) => { console.error(error); },
            onSuccess: () => onSuccess()
        });

        // Check if there are any previous uploads to continue.
        upload.findPreviousUploads().then((previousUploads) => {
            // Found previous uploads so we select the first one. 
            if (previousUploads.length)
                upload.resumeFromPreviousUpload(previousUploads[0]);

            upload.start();
        });
    };

    const onSave = async () => {
        const dataUrl: string | undefined = signaturePad?.toDataURL('image/png');
        if (dataUrl) {
            const file = await urltoFile(dataUrl, `signature-${user.id}.png`, "image/png");
            handleUpload(file);
        }
    };

    const urltoFile = (url: string, filename: string, type: string): Promise<File> => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: type }); })
        );
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
            <AppBar color="default">
                <Toolbar>
                    <IconButton onClick={() => setOpen(false)}>
                        <Close />
                    </IconButton>

                    <Typography variant="h6" sx={{ ml: 2, flex: 1 }}>{t("Signature")}</Typography>

                    <Button onClick={() => signaturePad?.clear()} sx={{ mr: 1 }}>{t("Delete")}</Button>
                    <LoadingButton color="primary" variant="contained" onClick={onSave}>{t("Save")}</LoadingButton>
                </Toolbar>
            </AppBar>

            <SignaturePad ref={(ref: any) => setSignaturePad(ref)} redrawOnResize />

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={!isLandscape}>
                <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', px: 1 }}><ScreenRotation sx={{ mr: 1 }} />{t("PleaseUseLandscapeOrientation")}</Typography>
            </Backdrop>

            <LayoutSnackbar />
        </Dialog>
    );
}