import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Drawer, Typography } from "@mui/material";
import { BarcodeScanner } from "dynamsoft-javascript-barcode";
import { Fragment, useCallback, useEffect, useRef } from "react";
import { LayoutSnackbar } from "wcz-layout";

let scanner: BarcodeScanner | null = null;

interface CycleCountScannerProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    handleOnScan: (value: string) => void;
    disableAutoclose?: boolean;
}

export default function CycleCountScanner(props: CycleCountScannerProps) {
    const { open, setOpen, disableAutoclose, handleOnScan } = props;
    const elRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (open) initScannerAsync();
    }, [open]);

    const initScannerAsync = async () => {
        scanner = await BarcodeScanner.createInstance();
        await scanner.setUIElement(elRef.current!);
        scanner.onUniqueRead = (txt) => { handleOnScan(txt); !disableAutoclose && handleClose(); };
        await scanner.open();
    };

    const handleClose = useCallback(() => {
        setOpen(false);

        if (scanner)
            scanner.destroyContext();
    }, [scanner]);

    return (
        <Fragment>
            <Drawer anchor="bottom" open={open} onClose={handleClose}>
                <Typography sx={{ textAlign: 'center', cursor: 'pointer' }}>
                    <KeyboardArrowDown />
                </Typography>
                <Box ref={elRef} sx={{ width: "100vw", height: { xs: "70vh" }, position: 'relative' }} className="dce-video-container"></Box>
            </Drawer>

            <LayoutSnackbar />
        </Fragment>
    );
}