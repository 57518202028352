import { Clear, Delete, Search, ShoppingCart } from "@mui/icons-material";
import { Autocomplete, Badge, Box, Fab, Grid, IconButton, InputAdornment, List, ListItem, ListItemText, Tab, Tabs, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import moment from "moment";
import 'moment/locale/cs';
import 'moment/locale/en-gb';
import { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { fetchDelete, fetchGet, fetchPut, hasRole, LayoutContext } from "wcz-layout";
import { CartContext } from "../../contexts/CartContext";
import Cart from "../../models/Cart";
import Location from "../../models/Location";
import Material from "../../models/Material";
import { itRole } from "../../utils/Authorization";
import { apiUrl } from "../../utils/BaseUrl";
import CustomDialog from "../common/CustomDialog";
import MaterialDetail from "../material/MaterialDetail";
import CreateHandover from "./cartDialog/CreateHandover";
import MaterialType from "../../models/MaterialType";
import CartItem from "../../models/CartItem";

let hostnameChangeTimeoutId: NodeJS.Timeout | null = null;

interface CartDialogProps {
    refetch: () => void
}

export default function CartDialog(props: CartDialogProps) {
    const { refetch } = props;
    const [open, setOpen] = useState<boolean>(false);
    const { carts, refetchCarts } = useContext(CartContext);
    const [tab, setTab] = useState<number>(0);
    const { t, i18n } = useContext(LayoutContext);
    const cart: Cart = useMemo(() => carts[tab], [carts, tab]);
    const [searchText, setSearchText] = useState<string>("");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        open && refetchCarts();
    }, [open]);

    const { data: locations = [] } = useQuery<Location[]>(["locations", "cart"], ({ signal }) => fetchGet(`${apiUrl}/v1/location?isWarehouse=false`, signal), {
        enabled: open
    });

    const { data: materialTypes = [] } = useQuery<MaterialType[]>(["materialTypes"], ({ signal }) => fetchGet(`${apiUrl}/v1/materialType`, signal), {
        enabled: open
    });

    const { mutate: updateMaterial } = useMutation((req: Material) => fetchPut(`${apiUrl}/v1/material/${req.id}`, req), {
        onSuccess: () => {
            refetchCarts();
            refetch();
        }
    });

    const { mutate: removeMaterialFromCart } = useMutation((id: string) => fetchDelete(`${apiUrl}/v1/cart/item/${id}`), {
        onSuccess: () => {
            refetchCarts();
            refetch();
        }
    });

    useEffect(() => {
        if (!cart?.items.length) {
            setTab(0);
        }
    }, [cart])

    const { mutate: deleteCart } = useMutation(() => fetchDelete(`${apiUrl}/v1/cart/${cart.id}`), {
        onSuccess: () => {
            refetchCarts();
            refetch();
            onClose();
        }
    });

    useEffect(() => {
        if (open && inputRef.current)
            inputRef.current.focus();
    }, [open, inputRef.current]);

    const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value), []);

    const handleClearSearch = useCallback(() => setSearchText(""), []);

    const searchField = (
        <TextField variant="standard" inputRef={inputRef} placeholder={t("Search")} value={searchText} onChange={handleSearch} InputProps={{
            startAdornment: <InputAdornment position="start"><Search /> </InputAdornment>,
            endAdornment: <IconButton size="small" onClick={handleClearSearch}><Clear /></IconButton>
        }} />
    );

    const filteredCarts: Cart[] = useMemo(() => carts.filter(cart =>
        cart.employee?.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
        cart.employee?.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
        `${cart.employee?.firstName} ${cart.employee?.lastName}`.toLowerCase().includes(searchText.toLowerCase()) ||
        `${cart.employee?.lastName} ${cart.employee?.firstName}`.toLowerCase().includes(searchText.toLowerCase())
    ), [carts, searchText]);

    useEffect(() => {
        if (searchText && filteredCarts.length)
            setTab(carts.findIndex(cart => cart.id === filteredCarts[0].id));

    }, [searchText, filteredCarts]);

    const getCreatedDate = useCallback((date: string) => {
        const localLocale = moment(date);
        localLocale.locale(i18n.language);
        return localLocale.fromNow();
    }, [i18n.language]);

    const updateHostname = useCallback((item: CartItem) => (e: React.ChangeEvent<HTMLInputElement>) => {
        if (hostnameChangeTimeoutId)
            clearTimeout(hostnameChangeTimeoutId);

        hostnameChangeTimeoutId = setTimeout(() => updateMaterial({ ...item.material, hostname: e.target.value }), 1000);
    }, []);

    const onClose = useCallback(() => {
        setOpen(false);
        setSearchText("");
    }, []);

    if (!hasRole(itRole))
        return null;

    return (
        <Fragment>
            <Fab color="primary" onClick={() => setOpen(true)} sx={{ position: 'fixed', bottom: 10, right: 10 }}>
                <Badge color="secondary" badgeContent={carts.map(c => c.employee).length}>
                    <ShoppingCart />
                </Badge>
            </Fab>

            <CustomDialog open={open} onClose={onClose} maxWidth="md" title={fullScreen ? t(cart?.type) : searchField} color="default" disablePadding actions={
                [<CreateHandover key="deleteCart" onCreate={deleteCart} cart={cart} materialTypes={materialTypes} />]}>
                {!isEmpty(cart) ?
                    <Box sx={{ flexGrow: 1, display: 'flex', mt: 1, height: { xs: "calc(100vh - 117px)", sm: 600 } }}>
                        <Tabs orientation="vertical" variant="scrollable" value={tab} onChange={(e, value) => setTab(value)} sx={{ borderRight: 1, borderColor: 'divider' }}>
                            {filteredCarts.map((cart, index) => <Tab label={`${cart.employee?.firstName} ${cart.employee?.lastName}`} value={carts.findIndex(c => c.id === filteredCarts[index].id)} key={cart.id} />)}
                        </Tabs>

                        <Grid container>
                            <Grid item xs={12}>
                                <List sx={{ maxHeight: { xs: "calc(100vh - 117px)", sm: 600 }, overflow: 'auto' }}>
                                    {!!filteredCarts.length && cart.items.map(item => {
                                        const materialType = materialTypes.find(type => type.name === item.material?.type);

                                        return (
                                            <ListItem key={item.id}>
                                                <ListItemText primary={
                                                    <Grid container>
                                                        <Grid item xs={11} sm={10} md={8}>
                                                            <MaterialDetail material={item.material} />

                                                            {item.material.isHighValue &&
                                                                <Fragment>
                                                                    {materialType?.requireLocation &&
                                                                        <Autocomplete
                                                                            value={item.material.location}
                                                                            options={locations}
                                                                            getOptionLabel={option => {
                                                                                if (option.remark) return `${option.name} (${option.remark})`;
                                                                                return option.name;
                                                                            }}
                                                                            autoHighlight
                                                                            onChange={(e, value) => updateMaterial({ ...item.material, location: value ?? undefined })}
                                                                            renderInput={(params) => <TextField {...params} variant="standard" InputProps={{
                                                                                ...params.InputProps,
                                                                                startAdornment: <InputAdornment position="start">{t("Location")}: </InputAdornment>
                                                                            }} />}
                                                                        />
                                                                    }

                                                                    {materialType?.hasHostname &&
                                                                        <TextField variant="standard" defaultValue={item.material.hostname} onChange={updateHostname(item)} fullWidth margin="dense"
                                                                            InputProps={{ startAdornment: <InputAdornment position="start">{t("Hostname")}: </InputAdornment> }} />
                                                                    }

                                                                    {item.created && <Box sx={{ mt: 1 }}>
                                                                        <Typography variant="caption">{t("AddedByAt", { name: item.createdBy, date: getCreatedDate(item.created) })}</Typography>
                                                                    </Box>
                                                                    }
                                                                </Fragment>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                } />
                                                <IconButton onClick={() => removeMaterialFromCart(item.id)}>
                                                    <Delete />
                                                </IconButton>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Grid>
                        </Grid>

                    </Box>
                    :
                    <Grid container justifyContent="center" sx={{ my: 2 }}>
                        <Typography>{t("CartIsEmpty")}</Typography>
                    </Grid>
                }
            </CustomDialog>
        </Fragment>
    );
}