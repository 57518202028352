import { Box } from "@mui/material";

interface IconTypographyProps {
    children?: React.ReactNode;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    fontWeight?: number
}

export default function IconTypography(props: IconTypographyProps) {

    return (
        <Box sx={{ fontWeight: props.fontWeight, display: 'flex', alignItems: 'center' }}>
            {props.startIcon}
            {props.children}
            {props.endIcon}
        </Box>
    );
}