const normalizeValue = (value: any) => {
    return value === '' || value === null || value === undefined ? null : value;
};

export const areObjectsEqual = (obj1: any, obj2: any) => {
    const keys = Array.from(new Set([...Object.keys(obj1), ...Object.keys(obj2)]));
    for (const key of keys) {
        if (normalizeValue(obj1[key]) !== normalizeValue(obj2[key])) {
            return false;
        }
    }
    return true;
};