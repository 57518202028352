import { Info, Person, SwapHoriz, GroupRemove } from "@mui/icons-material";
import { Card, CardActions, CardHeader, Typography, Button, Grid } from "@mui/material";
import moment from "moment";
import { Fragment, useCallback, useContext, useState } from "react";
import Employee from "../../../models/Employee";
import { EmployeeStatus } from "../../../models/enums/EmployeeStatus";
import EmployeeDetail from "../../employee/EmployeeDetail";
import CardActionItems from "./common/CardActionItems";
import EmployeeTransferDetail from "../../employee/EmployeeTransferDetail";
import { useDeleteEmployee, useUpdateEmployee } from "../../../services/EmployeeService";
import { LayoutContext } from "wcz-layout";

interface SearchEmployeeCardProps {
    employee: Employee,
    refetch: () => void
}

export default function SearchEmployeeCard(props: SearchEmployeeCardProps) {
    const { employee, refetch } = props;
    const [employeeDetailId, setEmployeeDetailId] = useState<string>("");
    const [transferEmployeeId, setTransferEmployeeId] = useState<string>("");
    const { t } = useContext(LayoutContext);

    const { mutate: update } = useUpdateEmployee({
        onSuccess: () => refetch()
    });

    const { mutate: remove } = useDeleteEmployee({
        onSuccess: () => update({
            ...employee,
            previousEmployeeId: undefined
        })
    });

    const removePreviousEmployee = useCallback((previousEmployeeId: string) => () => remove(previousEmployeeId), [employee]);

    return (
        <Card variant="outlined" sx={{ mt: 1 }}>
            <CardHeader
                avatar={<Person color="primary" />}
                title={<Typography variant="body1">{`${employee.firstName} ${employee.lastName} (${employee.id})`}</Typography>}
                action={
                    <CardActionItems items={[
                        { label: t("Transfers"), onClick: () => setTransferEmployeeId(employee.id), icon: <SwapHoriz /> },
                        { label: "Detail", onClick: () => setEmployeeDetailId(employee.id), icon: <Info /> },
                    ]} />
                }
                subheader={
                    <Fragment>
                        <Typography variant="body2" color="text.secondary"><b>{t("Department")}: </b>{employee.department}</Typography>
                        <Typography variant="body2" color={employee.status === EmployeeStatus.Active ? "text.secondary" : "error.main"}><b>Status: </b>{employee.status}</Typography>
                        {employee.terminationFutureDate && <Typography variant="subtitle2" color="error.main"><b>{t("Termination")}: </b>{moment(employee.terminationFutureDate).formatDate()}</Typography>}
                    </Fragment>
                }
            />

            {employee.previousEmployeeId &&
                <CardActions>
                    <Grid container justifyContent="end">
                        <Grid item>
                            <Button size="small" startIcon={<GroupRemove />} onClick={removePreviousEmployee(employee.previousEmployeeId)}>{t("RemovePreviousId")}</Button>
                        </Grid>
                    </Grid>
                </CardActions>
            }

            <EmployeeDetail employeeId={employeeDetailId} setEmployeeId={setEmployeeDetailId} />
            <EmployeeTransferDetail employeeId={transferEmployeeId} setEmployeeId={setTransferEmployeeId} />
        </Card>
    );
}