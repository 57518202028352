import { Group, History, Home, Inventory2, Laptop, LocationOn } from '@mui/icons-material';
import { indigo, purple } from '@mui/material/colors';
import { useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { FileProvider } from 'wcz-file';
import { hasRole, LayoutProvider, LeftDrawerItem, User, useUser } from 'wcz-layout';
import packageJson from '../package.json';
import { CartProvider } from './contexts/CartContext';
import CycleCountDetail from './pages/CycleCountDetail';
import CycleCounts from './pages/CycleCounts';
import Employees from './pages/Employees';
import HomePage from './pages/HomePage';
import Locations from './pages/Locations';
import Materials from './pages/Materials';
import NewMaterial from './pages/NewMaterial';
import { itRole } from './utils/Authorization';
import { fileUrl } from './utils/BaseUrl';
import LogsPage from './pages/LogsPage';

export default function App() {
    const user: User = useUser();

    const leftDrawerItems: LeftDrawerItem[] = useMemo(() => [
        { title: "Layout.Home", path: "/", icon: <Home fontSize="large" /> },
        { title: "Locations", path: "/location", icon: <LocationOn fontSize="large" />, hidden: !hasRole(itRole) },
        { title: "Material", path: "/material", icon: <Laptop fontSize="large" />, hidden: !hasRole(itRole) },
        { title: "Employees", path: "/employee", icon: <Group fontSize="large" /> },
        { title: "CycleCount", path: "/cycle-counts", icon: <Inventory2 fontSize="large" />, hidden: !hasRole(itRole) },
        { title: "Logs", path: "/logs", icon: <History fontSize="large" />, hidden: !hasRole(itRole) },
    ], [user]);

    return (
        <BrowserRouter>
            <LayoutProvider title={packageJson.name} appVersion={packageJson.version} primaryColor={indigo[500]} secondaryColor={purple[500]} leftDrawerItems={leftDrawerItems}>
                <FileProvider baseUrl={fileUrl}>
                    <CartProvider>
                        <Routes>
                            <Route path='/' element={<HomePage />} />
                            <Route path='/:searchValue' element={<Home />} />
                            <Route path='/location' element={<Locations />} />
                            <Route path='/material' element={<Materials />} />
                            <Route path='/material/new' element={<NewMaterial />} />
                            <Route path='/employee' element={<Employees />} />
                            <Route path='/employee/:department' element={<Employees />} />
                            <Route path='/cycle-counts' element={<CycleCounts />} />
                            <Route path='/cycle-count/:id' element={<CycleCountDetail />} />
                            <Route path='/logs' element={<LogsPage />} />
                        </Routes>
                    </CartProvider>
                </FileProvider>
            </LayoutProvider>
        </BrowserRouter>
    );
}