import { Close } from "@mui/icons-material";
import { AppBar, Breakpoint, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { ReactNode } from "react";
import { LayoutSnackbar } from "wcz-layout";

interface CustomDialogProps {
    children?: ReactNode,
    title: string | ReactNode | null,
    open: boolean,
    onClose: () => void,
    color?: "success" | "info" | "warning" | "error" | "primary" | "secondary" | "inherit" | "default",
    maxWidth?: Breakpoint,
    actions?: ReactNode[],
    disablePadding?: boolean
}

export default function CustomDialog(props: CustomDialogProps) {
    const { open, onClose, title, children, color, maxWidth, actions, disablePadding } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Dialog open={open} onClose={onClose} fullScreen={fullScreen} fullWidth maxWidth={maxWidth}>
            {fullScreen ?
                <AppBar sx={{ position: "relative", bgcolor: `${color}.main` }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6">{title}</Typography>
                        <IconButton edge="start" onClick={onClose}>
                            <Close sx={{ color: `${color}.contrastText` }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                :
                <Grid container justifyContent="space-between" sx={{ bgcolor: `${color}.main`, color: `${color}.contrastText` }}>
                    <Grid item>
                        <DialogTitle>{title}</DialogTitle>
                    </Grid>

                    <Grid item sx={{ p: 1 }}>
                        <IconButton onClick={onClose}>
                            <Close fontSize="small" sx={{ color: `${color}.contrastText` }} />
                        </IconButton>
                    </Grid>
                </Grid>
            }

            {disablePadding ? children : <DialogContent>{children}</DialogContent>}

            {actions &&
                <DialogActions>
                    {actions.map(action => action)}
                </DialogActions>
            }

            <LayoutSnackbar />
        </Dialog>
    );
}
