import { useMutation } from "@tanstack/react-query";
import { Fragment, useContext, useMemo, useState } from "react";
import { LayoutContext, fetchPost } from "wcz-layout";
import Cart from "../../../models/Cart";
import Handover from "../../../models/Handover";
import MaterialType from "../../../models/MaterialType";
import { EmployeeStatus } from "../../../models/enums/EmployeeStatus";
import { apiUrl } from "../../../utils/BaseUrl";
import LoadingButton from "../../common/LoadingButton";
import SignatureDialog from "../../common/SignatureDialog";

const getHandover = (cart: Cart): Handover => {
    return {
        id: cart.id,
        items: [...cart.items],
        keeper: cart.employee!,
        type: cart.type
    };
};

interface CreateHandoverProps {
    onCreate: () => void,
    cart: Cart,
    materialTypes: MaterialType[]
}

export default function CreateHandover(props: CreateHandoverProps) {
    const { onCreate, cart, materialTypes } = props;
    const [signatureDialogOpen, setSignatureDialogOpen] = useState<boolean>(false);
    const { t } = useContext(LayoutContext);

    const { mutate: createHandover } = useMutation((req: Handover) => fetchPost(`${apiUrl}/v1/handover`, req), {
        onSuccess: () => {
            setSignatureDialogOpen(false);
            onCreate();
        }
    });

    const handleSubmitClick = () => {
        const isSignatureRequired: boolean = cart.items.some(item => materialTypes.filter(type => type.requireSignature).map(type => type.name).includes(item.material.type));
        if (isSignatureRequired)
            setSignatureDialogOpen(true);
        else
            createHandover(getHandover(cart));
    };

    const allMaterialsWithRequiredLocationHasLocation = useMemo(() => cart?.items.every(item => !materialTypes.filter(type => type.requireLocation).map(type => type.name).includes(item.material.type) || item.material.location), [cart, materialTypes]);

    if (!cart)
        return null;

    return (
        <Fragment>
            <LoadingButton variant="contained" onClick={handleSubmitClick} disabled={!cart.items || cart.employee?.status !== EmployeeStatus.Active || !allMaterialsWithRequiredLocationHasLocation}>{`${t(cart.type)} (${cart.items.length})`}</LoadingButton>

            <SignatureDialog open={signatureDialogOpen} setOpen={setSignatureDialogOpen} handoverId={cart.id} onSuccess={() => createHandover(getHandover(cart))} />
        </Fragment>
    );
}