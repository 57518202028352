import { GridValueGetterParams } from "@mui/x-data-grid-premium";
import moment from "moment";

export const getWarrantyDate = (orderDate: string, monthsOfWarranty: number) => {
    if (!orderDate)
        return undefined;

    return moment(orderDate).add(monthsOfWarranty, 'months').formatDate();
};

export const getCompanyByFixAsset = (fixAsset: string) => {
    if (!fixAsset || fixAsset.length < 4) return undefined;

    const companyCode: string = fixAsset.substring(0, 4);
    switch (companyCode) {
        case "L520": return "WCZ";
        case "L090": return "WSCZ";
        default: return companyCode;
    }
};

//DataGrid functions
export const warrantyDateGetter = (params: GridValueGetterParams) => {
    const orderDate: string = params.row.orderDate;

    if (!orderDate || !params.value)
        return null;

    return moment(orderDate).add(params.value, 'months').formatDate();
};

export const keeperGetter = (params: GridValueGetterParams) => {
    if (params.value) 
        return `${params.value.firstName} ${params.value.lastName} (${params.value.id})`;
        
    return null;
};