import { Sync, SyncDisabled, Upload } from "@mui/icons-material";
import { Button, Card, CardContent, CardHeader, Container, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { trimStart } from "lodash";
import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { fetchGet, fetchPost, LayoutContext, newGuid } from "wcz-layout";
import MaterialForm from "../components/material/MaterialForm";
import { MaterialState } from "../models/enums/MaterialState";
import FixedAsset from "../models/FixedAsset";
import Material, { MaterialOptions } from "../models/Material";
import { apiUrl, myfaUrl } from "../utils/BaseUrl";
import { getCompanyByFixAsset } from "../utils/MaterialHelpers";

const materialOptionsInit: MaterialOptions = { manufacturers: [], partNumbers: [], types: [], models: [], locations: [], currencies: [], companies: [] };

export default function NewMaterial() {
    const { changeTitle, t, i18n, snackbar } = useContext(LayoutContext);
    const [material, setMaterial] = useState<Material>({} as Material);
    const [materialHistory, setMaterialHistory] = useState<Material[]>([]);
    const [foundInMyfa, setFoundInMyfa] = useState<boolean>(false);
    const queryClient = useQueryClient();

    const { data: options = materialOptionsInit, refetch: refetchOptions } = useQuery<MaterialOptions>(["materialOptions"], ({ signal }) => fetchGet(`${apiUrl}/v1/material/options`, signal));

    const { refetch: getFixedAssets } = useQuery<FixedAsset[]>(["fixedAsset", material.serialNumber], ({ signal }) => fetchGet(`${myfaUrl}/v1/fixedasset?search=serialNumber==${material.serialNumber}`, signal), {
        enabled: false,
        onSuccess: data => {
            if (data.length) {
                const updatedMaterial: Material = material;
                const fixedAsset: FixedAsset = data[0];

                if (fixedAsset.assetNumber) {
                    const fixAsset: string = trimStart(fixedAsset.assetNumber, "0");
                    if (fixedAsset.company)
                        fixedAsset.assetNumber = fixedAsset.company + fixAsset;

                    updatedMaterial.fixAsset = fixedAsset.assetNumber;
                }
                if (fixedAsset.orderDate)
                    updatedMaterial.orderDate = fixedAsset.orderDate;
                if (fixedAsset.price)
                    updatedMaterial.price = fixedAsset.price;
                if (fixedAsset.currency)
                    updatedMaterial.currency = fixedAsset.currency;

                setMaterial(updatedMaterial);
                setFoundInMyfa(true);
            }
        },
    });

    useEffect(() => changeTitle(t("NewMaterial")), [i18n.language]);

    useEffect(() => setDefaultMaterialProps(), [material.type]);

    const setDefaultMaterialProps = () => {
        const initialMaterial = {} as Material;
        setMaterial({
            ...initialMaterial,
            id: newGuid(),
            orderDate: moment().formatDate(),
            monthsOfWarranty: 12,
            state: MaterialState.New,
            stockQuantity: 1,
            type: material.type
        });
    };

    useEffect(() => { material.serialNumber && search(); }, [material.serialNumber]);

    const search = () => {
        queryClient.cancelQueries(["fixedAsset"]);
        setFoundInMyfa(false);
        getFixedAssets();
    };

    useEffect(() => handleOnFixAssetChange(), [material.fixAsset]);

    const handleOnFixAssetChange = () => {
        let fixAsset: string | undefined = material.fixAsset;

        if (fixAsset?.length === 5 && fixAsset.endsWith("9")) {
            const companyCode: string = fixAsset.substring(0, 4).toUpperCase();
            if (options.highestFixAsset)
                fixAsset = `${companyCode}${options.highestFixAsset + 1}`;
        }

        setMaterial({ ...material, fixAsset: fixAsset });
    };

    const { mutate: create, isLoading: isCreating } = useMutation((req: Material) => fetchPost(`${apiUrl}/v1/material`, req), {
        onSuccess: (data) => {
            setDefaultMaterialProps();
            setMaterialHistory([data, ...materialHistory]);
            setFoundInMyfa(false);
            refetchOptions();
        }
    });

    const handleOnSave = () => {
        const newMaterial: Material = material;

        if (newMaterial.fixAsset) {
            newMaterial.company = getCompanyByFixAsset(newMaterial.fixAsset);

            if (newMaterial.company !== "WCZ" && newMaterial.company !== "WSCZ")
                return snackbar({ message: t("IncorrectCompanyCode"), severity: "warning" });
        }

        create(newMaterial);
    };

    const { mutate: importData, isLoading: isImporting } = useMutation((req: FormData) => fetchPost(`${apiUrl}/v1/material/import`, req), {
        onSuccess: (data: Material[]) => {
            snackbar({ message: t("Imported") });
            setMaterialHistory([...data, ...materialHistory]);
            refetchOptions();
        }
    });

    const handleImport = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files?.length) return;

        let formData: FormData = new FormData();
        formData.append('file', e.target.files[0]);
        importData(formData);
    }, []);

    const createEnabled: boolean = useMemo(() => material.type?.length > 1 && (material.fixAsset ? material.fixAsset.length === 12 : true), [material]);
    return (
        <Container maxWidth="md">
            <Grid container spacing={1}>
                <Grid item xs={12} sx={{ textAlign: 'right', mt: 1 }}>
                    <label htmlFor={`file-upload`}>
                        <input accept="*" id={`file-upload`} multiple type="file" hidden onChange={handleImport} disabled={isImporting} />
                        <Button variant="contained" size="small" component="span" disabled={isImporting} startIcon={<Upload />}>Import</Button>
                    </label>
                </Grid>
            </Grid>

            <Card sx={{ my: 1 }}>
                <CardContent>
                    <MaterialForm material={material} setMaterial={setMaterial} locations={options.locations} options={options} />

                    <Button variant="contained" fullWidth onClick={handleOnSave} disabled={!createEnabled || isCreating} startIcon={foundInMyfa ? <Sync /> : <SyncDisabled />} sx={{ mt: 2 }}>{t("Save")}</Button>
                </CardContent>
            </Card>

            <Card sx={{ my: 1, display: { xs: 'none', sm: 'inherit' } }}>
                <CardHeader title={t("History")} />
                <CardContent>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>SN</TableCell>
                                <TableCell>IMEI</TableCell>
                                <TableCell>Fix Asset</TableCell>
                                <TableCell>{t("Type")}</TableCell>
                                <TableCell>{t("Manufacturer")}</TableCell>
                                <TableCell>Model</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {materialHistory.map(m =>
                                <TableRow key={m.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                    <TableCell>{m.serialNumber}</TableCell>
                                    <TableCell>{m.imei}</TableCell>
                                    <TableCell>{m.fixAsset}</TableCell>
                                    <TableCell>{m.type}</TableCell>
                                    <TableCell>{m.manufacturer}</TableCell>
                                    <TableCell>{m.model}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </Container>
    );
}