import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useContext, useState } from "react";
import { FileViewer } from "wcz-file";
import { fetchPost, LayoutContext } from "wcz-layout";
import PaginationFilter from "../../models/base/PaginationFilter";
import PaginationResponse from "../../models/base/PaginationResponse";
import { MaterialState } from "../../models/enums/MaterialState";
import { TransferType } from "../../models/enums/TransferType";
import MaterialTransfer from "../../models/MaterialTransfer";
import { apiUrl } from "../../utils/BaseUrl";
import CustomDialog from "../common/CustomDialog";

const getPaginationResponse = (employeeId: string, materialId: string): PaginationFilter => {
    return {
        advancedFilter: {
            logic: "and",
            filters: [
                { field: "type", operator: "eq", value: TransferType.Delivery },
                { field: "newKeeper.id", operator: "eq", value: employeeId },
                { field: "material.id", operator: "eq", value: materialId },
            ]
        },
        orderBy: ['dateTime desc'],
        pageSize: 1
    };
};

interface SignatureDetailProps {
    materialId: string,
    setMaterialId: (materialId: string) => void,
    employeeId: string
}

export default function SignatureDetail(props: SignatureDetailProps) {
    const { materialId, setMaterialId, employeeId } = props;
    const [transfer, setTransfer] = useState<MaterialTransfer | null>(null);
    const { t } = useContext(LayoutContext);

    useQuery<PaginationResponse<MaterialTransfer>>(["signatureDetail", materialId], () => fetchPost(`${apiUrl}/v1/material/transfer/search`, getPaginationResponse(employeeId, materialId)), {
        enabled: !!materialId,
        onSuccess: data => data.data.length && setTransfer(data.data[0])
    });

    const onClose = () => {
        setMaterialId("");
        setTransfer(null);
    };

    if (!transfer)
        return null;

    return (
        <CustomDialog open={!!materialId} onClose={onClose} title={t("Signature")} color="info">
            <Typography gutterBottom>{t("SignaturePartOne", { name: `${transfer.newKeeper?.firstName} ${transfer.newKeeper?.lastName}` })}</Typography>
            <Typography gutterBottom><b> - {transfer.material.name}</b></Typography>
            {transfer.material.state !== MaterialState.Sold && <Typography>{t("SignaturePartTwo")}</Typography>}
            <FileViewer disableActions subId={transfer.handover.id} />
            <Typography><b>{t("Handover")}: </b>{moment(transfer.dateTime).formatDateTime()}</Typography>
        </CustomDialog>
    );
}