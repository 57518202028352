import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useCallback, useContext, useMemo } from "react";
import { LayoutContext, fetchGet, fetchPost } from "wcz-layout";
import { GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import Employee from "../../models/Employee";
import MaterialTransfer from "../../models/MaterialTransfer";
import PaginationFilter from "../../models/base/PaginationFilter";
import PaginationResponse from "../../models/base/PaginationResponse";
import { TransferType } from "../../models/enums/TransferType";
import { apiUrl } from "../../utils/BaseUrl";
import CustomDialog from "../common/CustomDialog";
import { keeperGetter } from "../../utils/MaterialHelpers";

const getPaginationResponse = (employeeId: string): PaginationFilter => {
    return {
        advancedFilter: {
            logic: "or",
            filters: [
                { field: "oldKeeper.id", operator: "eq", value: employeeId },
                { field: "newKeeper.id", operator: "eq", value: employeeId },
            ]
        },
        orderBy: ['dateTime desc']
    };
};

interface EmployeeTransferDetailProps {
    employeeId: string,
    setEmployeeId: (employeeId: string) => void
}

export default function EmployeeTransferDetail(props: EmployeeTransferDetailProps) {
    const { employeeId, setEmployeeId } = props;
    const { t } = useContext(LayoutContext);

    const { data: employee = {} as Employee } = useQuery<Employee>(["employee", employeeId], ({ signal }) => fetchGet(`${apiUrl}/v1/employee/${employeeId}`, signal), {
        enabled: !!employeeId
    });

    const { data: transfer = {} as PaginationResponse<MaterialTransfer>, isLoading } = useQuery<PaginationResponse<MaterialTransfer>>(["materialTransfers", "employee", employeeId], () => fetchPost(`${apiUrl}/v1/material/transfer/search`, getPaginationResponse(employeeId)), {
        enabled: !!employeeId
    });

    const columns: GridColDef[] = useMemo(() => [
        { field: 'dateTime', headerName: t("Date"), width: 160, type: 'date', valueFormatter: ({ value }) => moment(value).formatDateTime() },
        { field: 'createdBy', headerName: t("HandledBy"), width: 260, valueGetter: keeperGetter },
        { field: 'type', headerName: t("Type"), width: 150, type: 'singleSelect', valueOptions: Object.values(TransferType) },
        { field: 'material', headerName: t("Material"), width: 350, valueGetter: ({ value }) => value.name },
    ] as GridColDef[], [t]);

    const onClose = useCallback(() => setEmployeeId(""), []);

    return (
        <CustomDialog open={!!employeeId} onClose={onClose} title={`${employee.firstName} ${employee.lastName}`} color="info" maxWidth="lg" disablePadding>
            <TableContainer>
                <DataGridPremium rows={transfer.data ?? []} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading}
                    slotProps={{ toolbar: { export: true } as GridToolbarProps }} />
            </TableContainer>
        </CustomDialog>
    );
}