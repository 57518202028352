import { Box, Button, Grid } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDebounce } from 'use-debounce';
import { LayoutContext, fetchGet, fetchPost } from "wcz-layout";
import { MaterialOptions } from "../../models/Material";
import SearchModel from "../../models/SearchModel";
import { apiUrl } from "../../utils/BaseUrl";
import CartDialog from "../cart/CartDialog";
import EmployeesWithNewId from "./advancedSearch/EmployeesWithNewId";
import MaterialOfInactiveEmployees from "./advancedSearch/MaterialOfInactiveEmployees";
import MaterialWithoutLocation from "./advancedSearch/MaterialWithoutLocation";
import MaterialWithoutQuantity from "./advancedSearch/MaterialWithoutQuantity";
import SearchEmployeeCard from "./advancedSearch/SearchEmployeeCard";
import SearchInput from "./advancedSearch/SearchInput";
import SearchLocationCard from "./advancedSearch/SearchLocationCard";
import SearchMaterialCard from "./advancedSearch/SearchMaterialCard";
import { useCreateEmployeeSearchHistory } from "../../services/EmployeeSearchHistoryService";

export default function AdvancedSearch() {
    const { t } = useContext(LayoutContext);
    const queryClient = useQueryClient();
    const [inputValue, setInputValue] = useState<string>("");
    const [searchText] = useDebounce(inputValue, 500);
    const [viewSize, setViewSize] = useState<number>(10);

    const { data = {} as SearchModel, refetch, isFetching } = useQuery<SearchModel>(["search"], () => fetchPost(`${apiUrl}/v1/search`, { value: searchText }), {
        enabled: false
    });

    const { data: materialOptions = {} as MaterialOptions } = useQuery<MaterialOptions>(["materialOptions"], ({ signal }) => fetchGet(`${apiUrl}/v1/material/options`, signal));

    useEffect(() => {
        if (searchText.length >= 3)
            return search();
        if (searchText.length === 0)
            return clearData();
    }, [searchText]);

    const search = () => {
        queryClient.cancelQueries(["search"]);
        refetch();
    };

    const clearData = useCallback(() => {
        queryClient.setQueryData(["search"], {});
        setViewSize(10);
    }, []);

    const dataLength: number = useMemo(() => (data.employees?.length + data.locations?.length + data.materials?.length), [data]);

    const { mutate: createEmployeeSearchHistory } = useCreateEmployeeSearchHistory();

    useEffect(() => {
        if (dataLength) {
            const timeout = setTimeout(() => {
                createEmployeeSearchHistory({ searchText: searchText });
            }, 1000 * 2);
            return () => clearTimeout(timeout);
        }
    }, [dataLength]);

    const handleViewMore = useCallback(() => setViewSize(prev => prev + 10), []);

    return (
        <Box sx={{ mb: 9 }}>
            <SearchInput data={data} loading={isFetching} searchText={inputValue} setSearchText={setInputValue} />

            {dataLength ?
                <Fragment>
                    {data.employees?.slice(0, viewSize).map(employee => <SearchEmployeeCard employee={employee} key={employee.id} refetch={refetch} />)}
                    {data.materials?.slice(0, viewSize).map(material => <SearchMaterialCard material={material} key={material.id} refetch={refetch} options={materialOptions} />)}
                    {data.locations?.slice(0, viewSize).map(location => <SearchLocationCard location={location} key={location.id} refetch={refetch} />)}

                    {dataLength > viewSize &&
                        <Box sx={{ width: "100%", textAlign: "center", mt: 3 }}>
                            <Button variant="contained" onClick={handleViewMore}>{t("More")}</Button>
                        </Box>
                    }
                </Fragment>
                :
                <Grid container spacing={1} sx={{ mt: 0.1 }}>
                    <Grid item>
                        <MaterialWithoutLocation options={materialOptions} />
                    </Grid>
                    <Grid item>
                        <MaterialWithoutQuantity options={materialOptions} />
                    </Grid>
                    <Grid item>
                        <MaterialOfInactiveEmployees options={materialOptions} />
                    </Grid>
                    <Grid item>
                        <EmployeesWithNewId />
                    </Grid>
                </Grid>
            }

            <CartDialog refetch={refetch} />
        </Box>
    );
}