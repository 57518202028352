import { Add, Close, Edit, Info } from "@mui/icons-material";
import { Box, Grid, IconButton, Menu, TextField, Typography } from "@mui/material";
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-premium";
import moment from "moment";
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingButton, LayoutContext, newGuid } from "wcz-layout";
import { CellClassName, GridDeleteCellItem, GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import CycleCount from "../models/CycleCount";
import { CycleCountStatus } from "../models/enums/CycleCountStatus";
import { useCreateCycleCount, useDeleteCycleCount, useGetCycleCounts } from "../services/CycleCountService";

export default function CycleCounts() {
    const { t, i18n, changeTitle } = useContext(LayoutContext);
    const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; } | null>(null);
    const [newCycleCount, setNewCycleCount] = useState<CycleCount>({} as CycleCount);
    const navigate = useNavigate();

    useEffect(() => changeTitle(t("CycleCount")!), [i18n.language]);

    const { data = [], isLoading } = useGetCycleCounts();
    const remove = useDeleteCycleCount();

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'actions', type: 'actions', width: 50,
            getActions: (params: any) => [
                <GridActionsCellItem key="info" icon={params.row.status === CycleCountStatus.Confirmed ? <Info /> : <Edit />} label="Details" onClick={() => navigate(`/cycle-count/${params.id}`)} showInMenu />,
                <GridDeleteCellItem key="remove" id={params.id} remove={remove} showInMenu hidden={params.row.status === CycleCountStatus.Confirmed} />,
            ],
        },
        { field: 'name', headerName: t("Name"), width: 250, },
        {
            field: 'status', headerName: t("Status"), width: 150, type: 'singleSelect', valueOptions: Object.values(CycleCountStatus),
            cellClassName: (params) => params.row.status === CycleCountStatus.Confirmed && CellClassName.BackgroundSuccess
        },
        { field: 'startDate', headerName: t("StartDate"), width: 150, type: 'date', valueFormatter: ({ value }) => moment(value).formatDateTime() },
        { field: 'endDate', headerName: t("EndDate"), width: 150, type: 'date', valueFormatter: ({ value }) => moment(value).formatDateTime() },
    ] as GridColDef[], [i18n.language]);

    const handleOnRowDoubleClick = useCallback((params: GridRowParams) => navigate(`/cycle-count/${params.id}`), []);

    const { mutate: create } = useCreateCycleCount({
        onSuccess: data => navigate(`/cycle-count/${data.id}`)
    });

    const handleOnSave = useCallback(() => create({ ...newCycleCount, id: newGuid() }), [newCycleCount]);

    const openMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => setContextMenu({ mouseX: e.clientX, mouseY: e.clientY, }), []);

    const closeMenu = useCallback(() => {
        setContextMenu(null);
        setNewCycleCount({} as CycleCount);
    }, []);

    const handleOnCycleCountNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setNewCycleCount({ ...newCycleCount, name: e.target.value }), [newCycleCount]);

    return (
        <Fragment>
            <TableContainer>
                <DataGridPremium rows={data} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading} onRowDoubleClick={handleOnRowDoubleClick}
                    slotProps={{ toolbar: { items: [{ title: t("DataGrid.AddRecord"), onClick: openMenu, icon: <Add /> }] } as GridToolbarProps }} />
            </TableContainer>


            <Menu open={contextMenu !== null} onClose={closeMenu} anchorReference="anchorPosition" variant="menu"
                anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}>
                <Box sx={theme => ({ width: { xs: 300, sm: 320, md: 360, lg: 400, xl: 440 }, px: 2 })} role="presentation">
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h6">{t("NewCycleCount")}</Typography>
                        </Grid>

                        <Grid item>
                            <IconButton onClick={closeMenu}>
                                <Close fontSize="small" />
                            </IconButton>
                        </Grid>
                    </Grid>


                    <TextField label={t("Name")} fullWidth variant="standard" value={newCycleCount.name} onChange={handleOnCycleCountNameChange} margin="dense" />

                    <LoadingButton variant="contained" onClick={handleOnSave} sx={{ float: "right", my: 1 }}>{t("Save")}</LoadingButton>
                </Box>
            </Menu>
        </Fragment>
    );
}