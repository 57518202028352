import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useCallback, useContext, useMemo } from "react";
import { fetchGet, fetchPost, LayoutContext } from "wcz-layout";
import { GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import PaginationFilter from "../../models/base/PaginationFilter";
import PaginationResponse from "../../models/base/PaginationResponse";
import { TransferType } from "../../models/enums/TransferType";
import Material from "../../models/Material";
import MaterialTransfer from "../../models/MaterialTransfer";
import { apiUrl } from "../../utils/BaseUrl";
import CustomDialog from "../common/CustomDialog";
import { keeperGetter } from "../../utils/MaterialHelpers";

const getPaginationResponse = (materialId: string): PaginationFilter => {
    return {
        advancedFilter: {
            logic: "or",
            filters: [{ field: "material.id", operator: "eq", value: materialId }]
        },
        orderBy: ['dateTime desc']
    };
};

interface MaterialTransferDetailProps {
    materialId: string,
    setMaterialId: (materialId: string) => void
}

export default function MaterialTransferDetail(props: MaterialTransferDetailProps) {
    const { materialId, setMaterialId } = props;
    const { t } = useContext(LayoutContext);

    const { data: material = {} as Material } = useQuery<Material>(["material", materialId], ({ signal }) => fetchGet(`${apiUrl}/v1/material/${materialId}`, signal), {
        enabled: !!materialId
    });

    const { data: transfer = {} as PaginationResponse<MaterialTransfer>, isLoading } = useQuery<PaginationResponse<MaterialTransfer>>(["materialTransfers", materialId], () => fetchPost(`${apiUrl}/v1/material/transfer/search`, getPaginationResponse(materialId)), {
        enabled: !!materialId
    });

    const columns: GridColDef[] = useMemo(() => [
        { field: 'dateTime', headerName: t("Date"), width: 160, type: 'date', valueFormatter: ({ value }) => moment(value).formatDateTime() },
        { field: 'createdBy', headerName: t("HandledBy"), width: 260, valueGetter: keeperGetter },
        { field: 'type', headerName: t("Type"), width: 150, type: 'singleSelect', valueOptions: Object.values(TransferType) },
        { field: 'oldKeeper', headerName: t("OldKeeper"), width: 260, valueGetter: keeperGetter },
        { field: 'newKeeper', headerName: t("NewKeeper"), width: 260, valueGetter: keeperGetter },
    ] as GridColDef[], [t]);

    const onClose = useCallback(() => setMaterialId(""), []);

    return (
        <CustomDialog open={!!materialId} onClose={onClose} title={material.name} color="info" maxWidth="lg" disablePadding>
            <TableContainer>
                <DataGridPremium rows={transfer.data ?? []} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading}
                    slotProps={{ toolbar: { export: true } as GridToolbarProps }} />
            </TableContainer>
        </CustomDialog>
    );
}