import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useCallback, useContext, useMemo, useEffect } from 'react';
import { LayoutContext, fetchGet } from 'wcz-layout';
import { GridToolbar, TableContainer } from 'wcz-x-data-grid';
import Trail from '../models/Trail';
import { apiUrl } from '../utils/BaseUrl';
import { TrailValueList } from '../components/common/TrailTable';

export default function LogsPage() {
    const { t, i18n, changeTitle } = useContext(LayoutContext);

    useEffect(() => changeTitle(t("Logs")), [i18n.language]);

    const { data: trails = [], isLoading } = useQuery<Trail[]>(["trails"], ({ signal }) => fetchGet(`${apiUrl}/v1/trail`, signal));

    const columns: GridColDef[] = useMemo(() => [
        { field: 'dateTime', headerName: t("Date"), width: 200, valueFormatter: ({ value }) => moment(value).formatDateTime() },
        { field: 'userName', headerName: t("UpdatedBy"), width: 150, },
        { field: 'userId', headerName: "ID", width: 150, },
        { field: 'type', headerName: t("Type"), width: 150, },
        { field: 'tableName', headerName: t("Name"), width: 150, },
        {
            field: 'oldValues', headerName: t("OldValues"), width: 500, renderCell: ({ value }) => {
                if (!value) return null;
                const object = JSON.parse(value);
                return <TrailValueList object={object} />;
            }
        },
        {
            field: 'newValues', headerName: t("NewValues"), width: 500, renderCell: ({ value }) => {
                if (!value) return null;
                const object = JSON.parse(value);
                return <TrailValueList object={object} />;
            }
        },
    ] as GridColDef[], [i18n.language]);

    const getRowHeight = useCallback(() => 'auto', []);

    return (
        <TableContainer>
            <DataGridPremium rows={trails} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading} getRowHeight={getRowHeight} />
        </TableContainer>
    );
}