import { AccountCircle, Add } from '@mui/icons-material';
import { Button, Card, CardActionArea, CardHeader, Container, Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { hasRole, LayoutContext } from 'wcz-layout';
import EmployeeDetail from '../components/employee/EmployeeDetail';
import AdvancedSearch from '../components/home/AdvancedSearch';
import { itRole } from '../utils/Authorization';

export default function HomePage() {
    const { changeTitle, user, t, i18n } = useContext(LayoutContext);
    const [employeeDetailId, setEmployeeDetailId] = useState<string>("");
    const navigate = useNavigate();

    useEffect(() => changeTitle(`IT ${t("EquipmentRoom").toLowerCase()}`), [i18n.language]);

    const getAuthorizedContent = (
        <Grid container spacing={1}>
            <Grid item xs={12} sx={{ textAlign: 'right' }}>
                <Button variant="contained" size="small" startIcon={<Add />} onClick={() => navigate("material/new")}>{t("NewMaterial")}</Button>
            </Grid>

            <Grid item xs={12}>
                <AdvancedSearch />
            </Grid>
        </Grid>
    );

    const getUnauthorizedContent = (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Card variant="outlined" onClick={() => setEmployeeDetailId(user.id)}>
                    <CardActionArea>
                        <CardHeader title={t("MyStuff")} avatar={<AccountCircle color="primary" />} />
                    </CardActionArea>
                </Card>
            </Grid>

            <EmployeeDetail employeeId={employeeDetailId} setEmployeeId={setEmployeeDetailId} />
        </Grid>
    );

    return (
        <Container sx={{ mt: 2 }}>
            {hasRole(itRole) ? getAuthorizedContent : getUnauthorizedContent}
        </Container>
    );
}