import { Autocomplete, Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Fragment, useContext, useMemo, useState } from "react";
import { fetchGet, fetchPut, LayoutContext } from "wcz-layout";
import Location, { LocationOptions } from "../../models/Location";
import { apiUrl } from "../../utils/BaseUrl";
import CustomDialog from "../common/CustomDialog";
import { areObjectsEqual } from "../../utils/Helpers";

const locationOptionsInit: LocationOptions = { buildings: [], equipmentRooms: [], racks: [], shelfs: [], boxes: [] };

interface LocationFormProps {
    locationId: string,
    setLocationId: (materialId: string) => void,
    search: () => void
}

export default function LocationForm(props: LocationFormProps) {
    const { locationId, setLocationId, search } = props;
    const [location, setLocation] = useState<Location>({} as Location);
    const { t } = useContext(LayoutContext);

    const { data: originalLocation = {} as Location } = useQuery<Location>(["location", locationId], ({ signal }) => fetchGet(`${apiUrl}/v1/location/${locationId}`, signal), {
        enabled: !!locationId,
        onSuccess: data => setLocation(data)
    });

    const { data: options = locationOptionsInit } = useQuery<LocationOptions>(["locationOptions"], ({ signal }) => fetchGet(`${apiUrl}/v1/location/options`, signal), {
        enabled: !!locationId
    });

    const { mutate: update } = useMutation((req: Location) => fetchPut(`${apiUrl}/v1/location/${req.id}`, req), {
        onSuccess: () => { search(); onClose(); }
    });

    const onIsWarehouseChange = (checked: boolean) => {
        setLocation({
            ...location,
            isWarehouse: checked,
            name: "",
            equipmentRoom: undefined,
            building: undefined,
            rack: undefined,
            shelf: undefined,
            box: undefined
        });
    };

    const handleOnSave = () => {
        if (location.isWarehouse)
            location.name = `${location.building}${location.equipmentRoom}${location.rack}${location.shelf}${location.box ?? ""}`;

        update(location);
    };

    const onClose = () => setLocationId("");

    const updateEnabled: boolean = useMemo(() => {
        return !areObjectsEqual(location, originalLocation);
    }, [location, originalLocation]);

    return (
        <CustomDialog open={!!locationId} onClose={onClose} title={t("Edit")} color="primary" actions={[
            <Button key="save" disabled={!updateEnabled} onClick={handleOnSave}>{t("Save")}</Button>]}>
            <Grid container spacing={1}>
                {!location.isWarehouse ?
                    <Grid item xs={12}>
                        <TextField variant="standard" fullWidth label={t("Name")} margin="dense" value={location.name ?? ""} onChange={e => setLocation({ ...location, name: e.target.value })} required={!location.isWarehouse} error={!location.isWarehouse && !location.name?.length} />
                    </Grid>
                    :
                    <Fragment>
                        <Grid item xs={12}>
                            <Autocomplete
                                value={location.building ?? ""}
                                options={options.buildings}
                                freeSolo
                                onInputChange={(e, value) => setLocation({ ...location, building: value })}
                                renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={t("Building")} required={location.isWarehouse} error={location.isWarehouse && location.building?.length !== 2} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                value={location.equipmentRoom ?? ""}
                                options={options.equipmentRooms}
                                freeSolo
                                onInputChange={(e, value) => setLocation({ ...location, equipmentRoom: value })}
                                renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={t("EquipmentRoom")} required={location.isWarehouse} error={location.isWarehouse && location.equipmentRoom?.length !== 2} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                value={location.rack ?? ""}
                                options={options.racks}
                                freeSolo
                                onInputChange={(e, value) => setLocation({ ...location, rack: value })}
                                renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={t("Rack")} required={location.isWarehouse} error={location.isWarehouse && location.rack?.length !== 3} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                value={location.shelf ?? ""}
                                options={options.shelfs}
                                freeSolo
                                onInputChange={(e, value) => setLocation({ ...location, shelf: value })}
                                renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={t("Shelf")} required={location.isWarehouse} error={location.isWarehouse && location.shelf?.length !== 2} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                value={location.box ?? ""}
                                options={options.boxes}
                                freeSolo
                                onInputChange={(e, value) => setLocation({ ...location, box: value ? value : undefined })}
                                renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={t("Box")} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="standard" fullWidth label={t("Remark")} margin="dense" value={location.remark ?? ""} onChange={e => setLocation({ ...location, remark: e.target.value })} multiline />
                        </Grid>
                    </Fragment>
                }
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={location.isWarehouse ?? false} onChange={e => onIsWarehouseChange(e.target.checked)} />} label={t('IsWarehouse')} />
                    </FormGroup>
                </Grid>
            </Grid>
        </CustomDialog>
    );
}