import { BorderColor, KeyboardReturn } from "@mui/icons-material";
import { DataGridPremium, GridActionsCellItem, GridColDef, GridValidRowModel } from "@mui/x-data-grid-premium";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { Fragment, useCallback, useContext, useMemo, useState } from "react";
import { fetchGet, fetchPost, fetchPut, hasRole, LayoutContext } from "wcz-layout";
import { AutocompleteEditInputCell, editInputCellValidation, GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import SignatureDetail from "../../components/employee/SignatureDetail";
import { CartContext } from "../../contexts/CartContext";
import Employee from "../../models/Employee";
import { MaterialState } from "../../models/enums/MaterialState";
import Material, { MaterialOptions } from "../../models/Material";
import { itRole } from "../../utils/Authorization";
import { apiUrl } from "../../utils/BaseUrl";
import { warrantyDateGetter } from "../../utils/MaterialHelpers";
import CustomDialog from "../common/CustomDialog";
import ReceiveMaterialMenu, { ReceiveMaterialMenuModel } from "../common/ReceiveMaterialMenu";
import ReceiveAllMaterialMenu, { ReceiveAllMaterialMenuModel } from "./employeeDetail/ReceiveAllMaterialMenu";

const materialOptionsInit: MaterialOptions = { manufacturers: [], partNumbers: [], types: [], models: [], locations: [], currencies: [], companies: [] };

interface EmployeeDetailProps {
    employeeId: string,
    setEmployeeId: (employeeId: string) => void
}

export default function EmployeeDetail(props: EmployeeDetailProps) {
    const { employeeId, setEmployeeId } = props;
    const [detailMaterialId, setDetailMaterialId] = useState<string>("");
    const [receiveMaterialMenu, setReceiveMaterialMenu] = useState<ReceiveMaterialMenuModel | null>(null);
    const [receiveAllMaterialMenu, setReceiveAllMaterialMenu] = useState<ReceiveAllMaterialMenuModel | null>(null);
    const { isMaterialInCart, carts } = useContext(CartContext);
    const { user, t, i18n, snackbar } = useContext(LayoutContext);

    const { data: employee = {} as Employee, refetch, isLoading } = useQuery<Employee>(["employee", employeeId], ({ signal }) => fetchGet(`${apiUrl}/v1/employee/${employeeId}`, signal), {
        enabled: !!employeeId
    });

    const { data: options = materialOptionsInit, refetch: refetchOptions } = useQuery<MaterialOptions>(["materialOptions"], ({ signal }) => fetchGet(`${apiUrl}/v1/material/options`, signal), {
        enabled: hasRole(itRole)
    });

    const create = useMutation((req: GridValidRowModel) => fetchPost(`${apiUrl}/v1/material`, req), { onSuccess: () => refetchOptions() });
    const update = useMutation((req: GridValidRowModel) => fetchPut(`${apiUrl}/v1/material/${req.id}`, req), { onSuccess: () => refetchOptions() });

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'actions', type: 'actions', width: 50,
            getActions: (params: any) => {
                const material: Material = params.row;
                const canBeReturned: boolean = !!material.keeper?.id && material.state !== MaterialState.Sold && !isMaterialInCart(material) && hasRole(itRole);

                if (canBeReturned)
                    return [
                        <GridActionsCellItem key="receive" icon={<KeyboardReturn color="primary" />} label={t("Receive")} onClick={(e: any) => setReceiveMaterialMenu({ mouseX: e.clientX, mouseY: e.clientY, material: material })} showInMenu />,
                        <GridActionsCellItem key="details" icon={<BorderColor color="primary" />} label="Details" onClick={() => setDetailMaterialId(params.id)} showInMenu />
                    ];
                else if (user.id === employee.id || hasRole(itRole))
                    return [<GridActionsCellItem key="details" icon={<BorderColor color="primary" />} label="Details" onClick={() => setDetailMaterialId(params.id)} showInMenu={hasRole(itRole)} />];

                return [];
            }
        },
        { field: 'company', headerName: t("Company"), editable: hasRole(itRole), width: 120, renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.companies} freeSolo /> },
        {
            field: 'type', headerName: t("Type"), editable: hasRole(itRole), width: 150, preProcessEditCellProps: editInputCellValidation.hasLength,
            renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.types.map(t => t.name)} />
        },
        { field: 'manufacturer', headerName: t("Manufacturer"), width: 150, editable: hasRole(itRole), renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.manufacturers} freeSolo /> },
        { field: 'model', headerName: "Model", width: 220, editable: hasRole(itRole), renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.models} freeSolo /> },
        { field: 'hostname', headerName: t("Hostname"), editable: hasRole(itRole), width: 200, },
        { field: 'serialNumber', headerName: "SN", editable: hasRole(itRole), width: 170, },
        { field: 'fixAsset', headerName: "Fix Asset", width: 160, editable: hasRole(itRole) },
        { field: 'imei', headerName: "IMEI", width: 170, editable: hasRole(itRole) },
        { field: 'location', headerName: t("Location"), editable: hasRole(itRole), width: 220, valueGetter: ({ value }) => value?.name, renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.locations.map(l => l.name)} /> },
        { field: 'partNumber', headerName: "PN", editable: hasRole(itRole), width: 170, renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.partNumbers} freeSolo /> },
        { field: 'orderDate', headerName: t("OrderDate"), width: 150, editable: hasRole(itRole), type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'monthsOfWarranty', headerName: t("Warranty"), editable: hasRole(itRole), type: 'number', renderCell: warrantyDateGetter },
        { field: 'state', headerName: t("State"), width: 150, editable: hasRole(itRole), type: 'singleSelect', valueOptions: Object.values(MaterialState) },
        { field: 'underRepairTo', headerName: t("UnderRepairTo"), width: 150, editable: hasRole(itRole), type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'price', headerName: t("Price"), width: 120, editable: hasRole(itRole), type: 'number' },
        { field: 'currency', headerName: t("Currency"), width: 120, editable: hasRole(itRole), renderEditCell: params => <AutocompleteEditInputCell params={params} options={options.currencies} /> },
        { field: 'itId', headerName: "IT ID", editable: hasRole(itRole), width: 150 },
        { field: 'stockQuantity', headerName: t("StockQuantity"), editable: hasRole(itRole), width: 190, type: 'number' },
        { field: 'safetyStock', headerName: t("SafetyStock"), editable: hasRole(itRole), width: 190, type: 'number' },
        { field: 'remark', headerName: t("Remark"), editable: hasRole(itRole), width: 350, },
        { field: 'ip', headerName: "IP", editable: hasRole(itRole), width: 150, },
        { field: 'macLan', headerName: "MAC LAN", editable: hasRole(itRole), width: 250, },
        { field: 'macWlan', headerName: "MAC WLAN", editable: hasRole(itRole), width: 250, },
    ] as GridColDef[], [employee.id, i18n.language, carts]);

    const processRowUpdate = async (row: GridValidRowModel): Promise<GridValidRowModel> => {
        const newMaterial: Material = row as Material;

        if (newMaterial.fixAsset) {
            newMaterial.fixAsset = newMaterial.fixAsset.toUpperCase();
            const companyCode: string = newMaterial.fixAsset.substring(0, 4);
            if (companyCode !== "L090" && companyCode !== "L520") {
                snackbar({ message: t("IncorrectCompanyCode"), severity: "warning" });
                throw new Error();
            }
        }

        const locationName: string | null = row.location;
        if (locationName)
            newMaterial.location = options.locations.find(l => l.name === locationName)!;

        if (row.isNew)
            await create.mutateAsync(newMaterial);
        else {
            newMaterial.keeper = employee.materials.find(m => m.id === row.id)!.keeper;
            await update.mutateAsync(newMaterial);
        }

        return { ...newMaterial, isNew: false };
    };

    const returnableMaterials: Material[] = useMemo(() => employee.materials?.filter(m => m.state !== MaterialState.Sold) ?? [], [employee.materials]);

    const handleReceiveAll = useCallback((e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => {
        setReceiveAllMaterialMenu({ mouseX: e.clientX, mouseY: e.clientY, materials: returnableMaterials });
    }, [returnableMaterials]);

    return (
        <Fragment>
            <CustomDialog open={!!employeeId} onClose={() => setEmployeeId("")} title={`${employee.firstName} ${employee.lastName} (${employee.id})`} color="info" maxWidth="xl" disablePadding>
                <TableContainer>
                    <DataGridPremium rows={employee.materials ?? []} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading}
                        editMode="row" processRowUpdate={processRowUpdate}
                        slotProps={{
                            toolbar: {
                                hideAddRecord: true,
                                export: true,
                                items: [{ title: t("ReceiveAll"), onClick: handleReceiveAll, icon: <KeyboardReturn />, hidden: !hasRole(itRole) }]
                            } as GridToolbarProps
                        }} />
                </TableContainer>
            </CustomDialog>

            <SignatureDetail materialId={detailMaterialId} setMaterialId={setDetailMaterialId} employeeId={employeeId} />
            <ReceiveMaterialMenu menu={receiveMaterialMenu} setMenu={setReceiveMaterialMenu} refetch={refetch} />
            <ReceiveAllMaterialMenu menu={receiveAllMaterialMenu} setMenu={setReceiveAllMaterialMenu} refetch={refetch} />
        </Fragment>
    );
}